// 내부에서 발생하는 오류 종류
export enum InternalErrorKind {
  UncaughtException = "UncaughtException", // 그 외 알 수 없는 예외
  Fatal = "Fatal", // 애플리케이션을 재시작 할 만큼 심각한 오류
  Abort = "Abort", // 도중에 작업이 중단됨. 예를들면 unmount
  Network = "Network", // 네트워크 관련 오류
  ResponseCode = "ResponseCode", // 서버 응답 코드 관련 오류. 200 이 아닌 경우 발생
}

// 에러들의 공통분모..
export type HasErrorKind = {
  readonly kind: string; // 다른 error 또한 kind 를 가지고 있어야 함
};

// 내부에서 발생하는 오류
export type InternalError =
  | {
      readonly kind: InternalErrorKind.UncaughtException;
      readonly exception: unknown;
    }
  | {
      readonly kind: InternalErrorKind.Fatal | InternalErrorKind.Abort;
      readonly loc: string;
      readonly msg: string;
    }
  | {
      readonly kind: InternalErrorKind.Network;
    }
  | {
      readonly kind: InternalErrorKind.ResponseCode;
      readonly code: number;
      readonly msg?: unknown;
    };

export type Annotated = { __error_tag: "InternalError" };
export type AnnotatedInternalError = InternalError & Annotated;

/**
 * InternalErr 에 알아볼 수 있는 tag를 달아줌
 *
 * @param from
 * @returns
 */
export function mkErr<E extends InternalError>(from: E): E & Annotated {
  return { ...from, __error_tag: "InternalError" };
}

// InternalErr 인지 판단해줌.
export const isErr = (from: unknown): from is InternalError => {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const s1 = from as any;
  if (s1 === undefined) return false;
  if (s1.__error_tag !== "InternalError") {
    return false;
  }
  return typeof s1.kind === "string";
  /* eslint-enable @typescript-eslint/no-explicit-any */
};
