import { makeStyles, Theme } from "@material-ui/core";
import { gds_theme, gds_typo, gds_service } from "@ui/goono/styles/theme";

const useStyles = makeStyles((MuiTheme: Theme) => ({
  root: {
    overflowWrap: "break-word",
    ...gds_theme.notosans,
  },
  h1: {
    ...gds_typo.h1,
    [MuiTheme.breakpoints.down(gds_theme.break_sm)]: {
      ...gds_typo.h1_mobile,
    },
  },
  h2: {
    ...gds_typo.h2,
    [MuiTheme.breakpoints.down(gds_theme.break_sm)]: {
      ...gds_typo.h2_mobile,
    },
  },
  h3: {
    ...gds_typo.h3,
    [MuiTheme.breakpoints.down(gds_theme.break_sm)]: {
      ...gds_typo.h3_mobile,
    },
  },
  h4: {
    ...gds_typo.h4,
    [MuiTheme.breakpoints.down(gds_theme.break_sm)]: {
      ...gds_typo.h4_mobile,
    },
  },
  h5: {
    ...gds_typo.h5,
    [MuiTheme.breakpoints.down(gds_theme.break_sm)]: {
      ...gds_typo.h5_mobile,
    },
  },
  b1_r: { ...gds_typo.b1_r },
  b1_m: { ...gds_typo.b1_m },
  b1_b: { ...gds_typo.b1_b },

  b2_r: { ...gds_typo.b2_r },
  b2_m: { ...gds_typo.b2_m },
  b2_b: { ...gds_typo.b2_b },

  b3_r: { ...gds_typo.b3_r },
  b3_m: { ...gds_typo.b3_m },
  b3_b: { ...gds_typo.b3_b },
  b4_r: { ...gds_typo.b4_r },
  b4_m: { ...gds_typo.b4_m },
  b4_b: { ...gds_typo.b4_b },

  ele1_m: { ...gds_typo.ele1_m },
  ele1_b: { ...gds_typo.ele1_b },
  ele2_m: { ...gds_typo.ele2_m },
  ele2_b: { ...gds_typo.ele2_b },
  ele3_m: { ...gds_typo.ele3_m },
  ele3_b: { ...gds_typo.ele3_b },

  caption1_r: { ...gds_typo.caption1_r },
  caption1_m: { ...gds_typo.caption1_m },
  caption1_b: { ...gds_typo.caption1_b },
  caption2_r: { ...gds_typo.caption2_r },
  caption2_m: { ...gds_typo.caption2_m },
  caption2_b: { ...gds_typo.caption2_b },

  primary: { color: gds_theme.primary },
  primary_light: { color: gds_service.primary_300 },
  secondary: { color: gds_theme.service_green },
  text: { color: gds_theme.gray_7_text },
  title: { color: gds_theme.gray_9_title },
  error: { color: gds_theme.danger },
  disabled: { color: gds_theme.gray_5_body },
  white: { color: gds_theme.white },
  cool_text: { color: gds_service.cool_gray_700 },
}));

export default useStyles;
