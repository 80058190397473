import { OrganizationAdmin } from "@goono-commons/v3/organization";
import { InternalError } from "@redwit-commons/utils/exception2";
import {
  StateMachine3,
  StateMachineAction,
  mkReducer,
  transition,
} from "@redwit-react-commons/reducers/state3";
import { Dispatch } from "react";

export enum UserStateStatus {
  INIT = "UserStateStatus::INIT",
  SUCCESS = "UserStateStatus::SUCCESS",
}

export enum UserActionKind {
  REGIST_USER_INFO = "UserActionKind::TRY_REGIST_USER_INFO",
  CLEAR = "UserActionKind::CLEAR",
}

export type LoginUserInfo = {
  token: string;
} & OrganizationAdmin;

export type UserError = never;

export type UserState =
  | {
      readonly status: UserStateStatus.INIT;
    }
  | {
      readonly status: UserStateStatus.SUCCESS;
      readonly userInfo: LoginUserInfo;
    };

export type UserAction =
  | {
      readonly kind: UserActionKind.CLEAR;
    }
  | {
      readonly kind: UserActionKind.REGIST_USER_INFO;
      readonly userInfo: LoginUserInfo;
    };

const smid = "USER_STATE_MACHINE3";

export type UserStateMachineType = StateMachine3<
  UserStateStatus,
  UserState,
  UserActionKind,
  UserAction,
  UserError
>;

export const userStateMachine: UserStateMachineType = new StateMachine3<
  UserStateStatus,
  UserState,
  UserActionKind,
  UserAction,
  UserError
>(smid, { status: UserStateStatus.INIT }, [
  transition(
    UserStateStatus.INIT,
    UserStateStatus.SUCCESS,
    UserActionKind.REGIST_USER_INFO
  ),
  transition(
    UserStateStatus.SUCCESS,
    UserStateStatus.SUCCESS,
    UserActionKind.REGIST_USER_INFO
  ),
  transition(
    UserStateStatus.SUCCESS,
    UserStateStatus.INIT,
    UserActionKind.CLEAR
  ),
]);

export type DispatchUserAction = Dispatch<
  StateMachineAction<
    UserStateStatus,
    UserState,
    UserActionKind,
    UserAction,
    UserError
  >
>;

export default mkReducer<
  UserStateStatus,
  UserState,
  UserActionKind,
  UserAction,
  UserError
>(userStateMachine);

export const doUserAction = (
  dispatch: DispatchUserAction,
  nextAction: UserAction,
  onResolve: () => void = () => {},
  onReject: (err: UserError | InternalError) => void = () => {}
) => {
  dispatch(userStateMachine.newTryAction(nextAction, onResolve, onReject));
};

export const doUserActionAsync = (
  dispatch: DispatchUserAction,
  nextAction: UserAction
) => {
  return new Promise<void>((resolve, reject) => {
    dispatch(userStateMachine.newTryAction(nextAction, resolve, reject));
  });
};

export const resetUser = (dispatch: DispatchUserAction) => {
  dispatch(userStateMachine.newResetAction());
};
