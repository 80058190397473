import React from "react";
import { createTheme } from "@material-ui/core";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core";
import { CssBaseline } from "@material-ui/core";
import { gds_theme, gds_service, gds_typo } from "./theme";
import { ReactComponent as CheckboxIcon } from "../images/icons/Checkbox.svg";
import { ReactComponent as CheckboxCheckedIcon } from "../images/icons/CheckboxChecked.svg";
import { ReactComponent as CheckboxIndeterminateIcon } from "../images/icons/CheckboxIndeterminate.svg";

import { ReactComponent as RadioIcon } from "../images/icons/Radio.svg";
import { ReactComponent as RadioCheckedIcon } from "../images/icons/RadioChecked.svg";

declare module "@material-ui/core/styles/createBreakpoints" {
  interface BreakpointOverrides {
    xxs: true; // adds the `xxs` breakpoint
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
  }
}

export const GoonoTheme = createTheme({
  breakpoints: {
    keys: ["xxs", "xs", "sm", "md", "lg", "xl"],
    values: {
      xxs: 0,
      xs: gds_theme.break_xxs,
      sm: gds_theme.break_xs,
      md: gds_theme.break_sm,
      lg: gds_theme.break_md,
      xl: gds_theme.break_lg,
    },
  },
  palette: {
    primary: {
      //light:
      main: gds_theme.primary,
      //dark:
      contrastText: gds_theme.white,
    },
    secondary: {
      main: gds_theme.service_green,
      contrastText: gds_theme.white,
    },
    error: {
      main: gds_theme.danger,
      contrastText: gds_theme.white,
    },
    warning: {
      main: gds_theme.warning,
      contrastText: gds_theme.white,
    },
    // info: {
    //main:
    // },
    success: {
      main: gds_theme.success,
      contrastText: gds_theme.white,
    },
    grey: {
      50: gds_service.gray_50,
      100: gds_service.gray_100,
      200: gds_service.gray_200,
      300: gds_service.gray_300,
      400: gds_service.gray_400,
      500: gds_service.gray_500,
      600: gds_service.gray_600,
      700: gds_service.gray_700,
      800: gds_service.gray_800,
      900: gds_service.gray_900,
    },
    text: {
      primary: gds_theme.gray_9_title,
      secondary: gds_theme.gray_7_text,
      disabled: gds_theme.gray_5_body,
      hint: gds_theme.gray_5_body,
    },
    divider: gds_theme.gray_3,
  },
  typography: {
    htmlFontSize: 16,
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    h1: {
      ...gds_typo.h1,
    },
    h2: {
      ...gds_typo.h2,
    },
    h3: {
      ...gds_typo.h3,
    },
    h4: {
      ...gds_typo.h4,
    },
    h5: {
      ...gds_typo.h5,
    },
    subtitle1: { ...gds_typo.b1_m },
    subtitle2: { ...gds_typo.b2_m },
    body1: { ...gds_typo.b2_r },
    body2: { ...gds_typo.b3_r },
    button: {
      ...gds_typo.ele2_m,
    },
    caption: {
      ...gds_typo.caption1_b,
    },
    fontFamily: [
      "NotoSansKR",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      "sans-serif",
    ].join(","),
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        html: {
          boxSizing: "border-box",
          "& *": { boxSizing: "border-box" },
        },
      },
    },
    MuiButtonBase: {
      root: {
        ...gds_theme.btn_transition,
      },
    },
    MuiButton: {
      root: {
        textTransform: "unset",
      },
    },
    MuiContainer: {
      root: {
        paddingLeft: 20,
        paddingRight: 20,
        maxWidth: 1280,
        "@media (min-width: 361px)": {
          paddingLeft: 20,
          paddingRight: 20,
        },
      },
      maxWidthLg: {
        maxWidth: 1280,
      },
    },
    MuiTooltip: {
      tooltip: {
        padding: "8px 8px",
        borderRadius: gds_theme.round_4,
        backgroundColor: gds_theme.gray_9_title,
        color: gds_theme.white,
        ...gds_typo.caption1_r,
      },
      arrow: {
        color: gds_theme.gray_9_title,
      },
    },
    MuiFormControlLabel: {
      root: {
        margin: 0,
        "&$disabled": {
          color: gds_theme.gray_5_body,
        },
      },
      label: {
        marginLeft: 4,
        color: gds_theme.gray_7_text,
        ...gds_typo.ele1_m,
      },
    },
    MuiCheckbox: {
      root: {
        padding: 0,
        width: 24,
        height: 24,
        color: gds_theme.gray_7_text,
        "&$checked ~ .MuiFormControlLabel-label": {
          color: gds_theme.gray_9_title,
          ...gds_typo.ele1_b,
        },
        "&$disabled": {
          color: gds_theme.gray_3,
        },
      },
      indeterminate: { color: gds_theme.gray_7_text },
      colorPrimary: {
        "&$checked": {
          color: gds_theme.primary,
        },
        "&$indeterminate": { color: gds_theme.primary },
        "&$disabled": { color: gds_theme.gray_3 },
      },
    },
    MuiTableContainer: {
      root: {
        border: `1px solid ${gds_theme.gray_3}`,
        borderRadius: gds_theme.round_3,
      },
    },
    MuiTable: {
      root: {
        tableLayout: "fixed",
      },
    },
    MuiTableCell: {
      sizeSmall: {
        "&$root": {
          padding: "0 0 0 20px",
          "@media (max-width: 1281px)": {
            paddingLeft: 10,
          },
        },
        "&$head": {
          background: gds_theme.gray_1,
          height: 48,
          color: gds_theme.gray_7_text,
          zIndex: 1,
          borderBottom: `1px solid ${gds_theme.gray_3}`,
          ...gds_typo.b4_b,
        },
        "&$body": {
          position: "relative",
          padding: "10px 0 10px 20px",
          background: gds_theme.white,
          borderBottom: `1px solid ${gds_theme.gray_3}`,
          color: gds_theme.gray_9_title,
          ...gds_theme.notosans,
          ...gds_typo.b4_r,
        },
      },
    },
  },
  props: {
    MuiContainer: {
      maxWidth: false,
    },
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiButton: {
      disableRipple: true,
    },
    MuiCheckbox: {
      color: "primary",
      disableRipple: true,
      icon: <CheckboxIcon />,
      checkedIcon: <CheckboxCheckedIcon />,
      indeterminateIcon: <CheckboxIndeterminateIcon />,
    },
    MuiRadio: {
      disableRipple: true,
      icon: <RadioIcon />,
      checkedIcon: <RadioCheckedIcon />,
    },
    MuiTable: {
      size: "small",
    },
    MuiDialog: {
      maxWidth: false,
    },
  },
});

GoonoTheme.typography.h1 = {
  ...gds_typo.h1,
  [GoonoTheme.breakpoints.down("sm")]: {
    ...gds_typo.h1_mobile,
  },
};
GoonoTheme.typography.h2 = {
  ...gds_typo.h2,
  [GoonoTheme.breakpoints.down("sm")]: {
    ...gds_typo.h2_mobile,
  },
};
GoonoTheme.typography.h3 = {
  ...gds_typo.h3,
  [GoonoTheme.breakpoints.down("sm")]: {
    ...gds_typo.h3_mobile,
  },
};
GoonoTheme.typography.h4 = {
  ...gds_typo.h4,
  [GoonoTheme.breakpoints.down("sm")]: {
    ...gds_typo.h4_mobile,
  },
};
GoonoTheme.typography.h5 = {
  ...gds_typo.h5,
  [GoonoTheme.breakpoints.down("sm")]: {
    ...gds_typo.h5_mobile,
  },
};

/**
 * @param GoonoThemeProvider
 * <EmotionThemeProvider theme={GoonoTheme}>
 *  <GoonoThemeProvider />
 * </EmotionThemeProvider>
 * Wrapping 필요
 * */
type GoonoThemeProviderProps = {
  children: React.ReactNode;
};

const GoonoThemeProvider: React.FC<GoonoThemeProviderProps> = ({
  children,
}) => {
  return (
    <MuiThemeProvider theme={GoonoTheme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
};
export default GoonoThemeProvider;
