import T from "@redwit-commons/utils/typecheck";

export enum ProjectAuthType {
  ADMIN = "Project::Admin",
  RW = "Project::RW",
  READONLY = "Project::ReadOnly",
}

export const ProjectAuthTypeValues = [
  ProjectAuthType.ADMIN,
  ProjectAuthType.RW,
  ProjectAuthType.READONLY,
];

export const UserProjectMapObjectSchema = T.object()
  .addField("UserId", T.string())
  .addField("ProjectId", T.string())
  .addField("pinned", T.string())
  .addField("authType", T.string().withEnum(ProjectAuthTypeValues))
  .addField("deactivated", T.boolean(), false);

export type UserProjectMapObjectCore = {
  readonly UserId: string;
  readonly ProjectId: string;
  readonly pinned: string;
  readonly authType: ProjectAuthType;
  readonly deactivated?: boolean;
};
