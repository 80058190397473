/* eslint-disable @typescript-eslint/no-explicit-any */

import Ajv, { ValidateFunction } from "ajv";
import { launchBgTask } from "./bgtask";
import { InternalErrorKind, mkErr } from "./exception2";

const ajvOptions = {
  removeAdditional: true,
  allErrors: true,
  coerceTypes: true,
};
const ajv = new Ajv(ajvOptions);
const allValidators: { [key: string]: ValidateFunction | undefined } = {};

export class SchemaObject {
  properties?: any;
  required?: any;
  enum?: any;
  items?: any;
  type?: string;
  anyOf?: any;
  allOf?: any;
  oneOf?: any;

  private constructor(type?: string) {
    this.type = type;
  }

  withEnum(enums: any[]): SchemaObject {
    this.enum = enums;
    return this;
  }
  static oneOf(schemaObjects: SchemaObject[]): SchemaObject {
    const ret = new SchemaObject();
    ret.oneOf = schemaObjects;
    return ret;
  }
  static anyOf(schemaObjects: SchemaObject[]): SchemaObject {
    const ret = new SchemaObject();
    ret.anyOf = schemaObjects;
    return ret;
  }
  static allOf(schemaObjects: SchemaObject[]): SchemaObject {
    const ret = new SchemaObject();
    ret.allOf = schemaObjects;
    return ret;
  }
  static array(itemType: SchemaObject): SchemaObject {
    const ret = new SchemaObject("array");
    ret.items = itemType;
    return ret;
  }
  static string(): SchemaObject {
    return new SchemaObject("string");
  }
  static number(): SchemaObject {
    return new SchemaObject("number");
  }
  static integer(): SchemaObject {
    return new SchemaObject("integer");
  }
  static boolean(): SchemaObject {
    return new SchemaObject("boolean");
  }
  static object(): SchemaObject {
    return new SchemaObject("object");
  }
  addField(name: string, format: SchemaObject, required = true): SchemaObject {
    if (this.properties === undefined) {
      this.properties = {};
    }
    this.properties[name] = format;
    if (required) {
      if (this.required === undefined) {
        this.required = [];
      }
      this.required.push(name);
    }
    return this;
  }
  delField(name: string): SchemaObject {
    if (this.properties === undefined) {
      this.properties = {};
    }
    delete this.properties[name];
    if (this.required === undefined) {
      this.required = [];
    }
    this.required = (this.required as string[]).filter((x) => x !== name);
    return this;
  }
  clone_partial(): SchemaObject {
    const ret = this.clone();
    ret.required = undefined;
    return ret;
  }
  clone(): SchemaObject {
    const values = JSON.stringify(this);
    const ret = new SchemaObject();
    Object.assign(ret, JSON.parse(values));
    return ret;
  }
  static mkValidator<T>(schema: object | SchemaObject): (input: any) => T {
    try {
      const unique_id = JSON.stringify(schema);
      const compileTask = () => {
        const existing = allValidators[unique_id];
        if (existing !== undefined) {
          // 이미 생성 완료
          return existing;
        }
        const validator = ajv.compile(schema);
        allValidators[unique_id] = validator;
        return validator;
      };
      launchBgTask(compileTask);
      return (input: any): T => {
        const validator = compileTask();
        const result = validator(input);
        if (result) {
          return input as T;
        }
        throw mkErr({
          kind: InternalErrorKind.Abort,
          loc: "typecheck::validator",
          msg: "Typecheck failed",
          schema,
          input,
        });
      };
    } catch (err) {
      throw mkErr({
        kind: InternalErrorKind.Fatal,
        loc: "typecheck::mkValidator",
        msg: "Schema compilation failed",
        schema,
        err,
      });
    }
  }

  static mkObjectExtractor<T>(
    schema: object | SchemaObject
  ): (input: any) => T {
    try {
      const unique_id = JSON.stringify(schema);
      const compileTask = () => {
        const validator = allValidators[unique_id] || ajv.compile(schema);
        const subExtractor: { [key: string]: (input: any) => T } = {};
        const subArrayExtractor: { [key: string]: (input: any) => T } = {};
        const properties: { [key: string]: SchemaObject } = (schema as any)
          .properties;
        if (properties !== undefined) {
          for (const key of Object.keys(properties)) {
            const format = properties[key];
            const type: string = format.type as string;
            if (type === "object") {
              subExtractor[key] = SchemaObject.mkObjectExtractor(format);
            } else if (type === "array") {
              subArrayExtractor[key] = SchemaObject.mkObjectExtractor(
                format.items
              );
            }
          }
        }
        return {
          validator,
          subArrayExtractor,
          subExtractor,
          properties,
        };
      };
      launchBgTask(compileTask);
      return (input: unknown): T => {
        const { validator, subArrayExtractor, subExtractor, properties } =
          compileTask();
        const temp_obj: { [key: string]: unknown } = {};
        if (properties !== undefined) {
          for (const key of Object.keys(properties)) {
            const format = properties[key];
            const type: string = format.type as string;
            let value = (input as any)[key];
            if (value === undefined || value === null) continue;
            switch (type) {
              case "string": {
                if (typeof value.toISOString === "function")
                  value = value.toISOString();
                else value = value.toString();
                // normalize is not available for some platforms (e.g. IE).
                // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/normalize
                if (typeof value.normalize === "function")
                  value = value.normalize();
                break;
              }
              case "object": {
                value = subExtractor[key](value);
                break;
              }
              case "array": {
                value = Array.from(value).map(subArrayExtractor[key]);
                break;
              }
            }
            temp_obj[key] = value;
          }
        }
        const result = validator(temp_obj);
        if (result) {
          return temp_obj as unknown as T;
        }
        throw mkErr({
          kind: InternalErrorKind.Abort,
          loc: "typecheck::objectExtractor",
          msg: "Typecheck failed",
          schema,
          temp_obj,
        });
      };
    } catch (err) {
      throw mkErr({
        kind: InternalErrorKind.Fatal,
        loc: "typecheck::mkObjectExtractor",
        msg: "Schema compilation failed",
        schema,
        err,
      });
    }
  }
}

export const validateObject = SchemaObject.mkValidator<object>(
  SchemaObject.object()
);
export const validateString = SchemaObject.mkValidator<string>(
  SchemaObject.string()
);
export const validateBoolean = SchemaObject.mkValidator<boolean>(
  SchemaObject.boolean()
);
export const validateNumber = SchemaObject.mkValidator<number>(
  SchemaObject.number()
);
export const validateInteger = SchemaObject.mkValidator<number>(
  SchemaObject.integer()
);

export default SchemaObject;

/* eslint-enable @typescript-eslint/no-explicit-any */
