import React from "react";
import { Box, BoxProps } from "@material-ui/core";
import clsx from "clsx";

type FlexProps = {
  container?: boolean;
  item?: boolean;
  flexFlow?: string;
  gap?: string;
  fullWidth?: boolean;
  style?: React.CSSProperties;
  dataTestid?: string;
} & BoxProps;

function Flex({
  container = false,
  item = false,
  flexFlow,
  gap,
  flexDirection,
  flexWrap,
  justifyContent,
  alignContent,
  alignItems,
  alignSelf,
  order,
  flexGrow,
  flexShrink,
  flexBasis,
  flex,
  children,
  className,
  fullWidth,
  onClick,
  onDoubleClick,
  onMouseEnter,
  onMouseLeave,
  style,
  id,
  dataTestid,
  ...rest
}: FlexProps) {
  const displayFlex = container && container === true ? `flex` : undefined;
  const setContainerValue = (value: any) => {
    if (container && container === true) {
      return value;
    }
  };
  const setItemValue = (value: any) => {
    if (item && item === true) {
      return value;
    }
  };
  const componentStyle = style === undefined ? {} : style;

  return (
    <Box
      id={id}
      data-testid={dataTestid}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={clsx(className)}
      display={`${displayFlex}`}
      flexDirection={setContainerValue(flexDirection)}
      flexWrap={setContainerValue(flexWrap)}
      justifyContent={setContainerValue(justifyContent)}
      alignContent={setContainerValue(alignContent)}
      alignItems={setContainerValue(alignItems)}
      alignSelf={setItemValue(alignSelf)}
      order={setItemValue(order)}
      flexGrow={setItemValue(flexGrow)}
      flexShrink={setItemValue(flexShrink)}
      flexBasis={setItemValue(flexBasis)}
      flex={setItemValue(flex)}
      style={{
        flexFlow: setContainerValue(flexFlow),
        gap: setContainerValue(gap),
        width: fullWidth != undefined && fullWidth ? "100%" : undefined,
        ...componentStyle,
      }}
      {...rest}
    >
      {children}
    </Box>
  );
}

// Mui Grid는 Grid System을 위한 컴포넌트로서,
// 정렬에 사용하면 불필요한 속성이 default로 적용됨 (ex. flex-wrap, width:100%)

// Mui default theme이 override되지 않아
//breakpoint에 영향을 받는 Grid item의 lg,md,sm 등을 의도한대로 컨트롤하기 어려움

// 용도를 구분하고 가독성 개선을 위해 제작
export default Flex;
