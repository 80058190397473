export const ScreenURL = {
  /** landing */
  SIGN_IN: "/",
  AUTH_PW: "/auth/password",
  UPDATE_PW: "/update/password",
  LANDING_CALLBACK: "/landing/callback",

  /** service */
  MYPAGE: "/my-page",
  WORKSPACE_LIST: "/workspace/list",
  WORKSPACE_DASHBOARD: "/workspace/dashboard/:id",
  WORKSPACE_OVERVIEW: "/workspace/overview",
  SEARCH_RESULT: "/search/result",

  /** common */
  LOGOUT: "/logout",
} as const;
