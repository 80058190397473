/**
 * Request library for fetch API
 */

import { InternalErrorKind, mkErr } from "./exception2";

export const mkJSONHeader = (): Record<string, string> => {
  const headers: { [index: string]: string } = {};
  headers["Content-Type"] = "application/json";
  headers["Accept"] = "application/json";
  return headers;
};
export const mkAuthHeader = (token: string): Record<string, string> => {
  const headers: { [index: string]: string } = {};
  headers["Authorization"] = `Bearer ${token}`;
  return headers;
};

export const trim_slash = (input: string): string => {
  // 맨 앞, 맨 뒤 한번만 작동, g 옵션 없이
  return input.replace(/\/+$/, "").replace(/^\/+/, "");
};

export const mkGetURL = (
  host: string,
  endpoint: string,
  queryObj: { [key: string]: unknown } = {}
): string => {
  let url = `${trim_slash(host)}/${trim_slash(endpoint)}`;
  const listParams: string[] = [];
  for (const key in queryObj) {
    const entry = queryObj[key];
    if (
      typeof entry === "string" ||
      typeof entry === "number" ||
      typeof entry === "boolean"
    ) {
      const param = `${encodeURIComponent(key)}=${encodeURIComponent(
        entry.toString()
      )}`;
      listParams.push(param);
    } else if (entry !== undefined) {
      throw mkErr({
        kind: InternalErrorKind.Fatal,
        loc: "mkGetURL",
        msg: "Unsupported entry type",
        entry,
      });
    }
  }
  if (listParams.length > 0) {
    const querystring = listParams.join("&");
    url += `?${querystring}`;
  }
  return url;
};
