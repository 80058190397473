import T from "@redwit-commons/utils/typecheck";
import { AccessTime, withAccessTime } from "./access_time";
import {
  extractUserObject,
  UserObject,
  UserObjectSchema,
  UserObjectWithAuthTypeSchema,
  validateUserObjectWithAuthType,
} from "./user";
import {
  ProjectAuthType,
  UserProjectMapObjectCore,
  UserProjectMapObjectSchema,
} from "./user_project_map";

export type ProjectResearchInfo = {
  /**
   * @deprecated use researchName;
   *  */
  readonly researchName?: string;
  readonly researchOwner?: string;
  readonly researchNumber?: string;
  readonly researchStart?: string;
  readonly researchEnd?: string;
};
export type ProjectEmojiInfo = {
  readonly emoji_lbl?: string;
};
export type ProjectLockOptions = {
  readonly lock_delete?: boolean;
  readonly lock_download?: boolean;
};
type ProjectDeleteFlag = {
  deactivated?: boolean;
};
export type ProjectPureObjectCore = {
  readonly id: string;
  readonly name: string;
  readonly WorkspaceId?: string;
} & ProjectResearchInfo &
  ProjectEmojiInfo &
  ProjectLockOptions;

export type ProjectDBObjectCore = {
  readonly Users: Array<
    UserObject & { User_Project_Map: UserProjectMapObjectCore }
  >;
} & ProjectPureObjectCore &
  ProjectDeleteFlag;

export type RefinedProjectObjectCore = ProjectPureObjectCore & {
  readonly Users: Array<
    UserObject & { authType: ProjectAuthType; pinned: string }
  >;
};

export type ProjectDBObject = ProjectDBObjectCore & AccessTime;
export type ProjectPureObject = ProjectPureObjectCore & AccessTime;
export type ProjectObject = RefinedProjectObjectCore & AccessTime;

export const refineProjectObject = (rdbProject: ProjectDBObject) => {
  const Users = rdbProject.Users.map((user) => {
    const userObject = extractUserObject(user);
    const authType = user.User_Project_Map.authType;
    const pinned = user.User_Project_Map.pinned;
    const userObjWithAuth = validateUserObjectWithAuthType({
      ...userObject,
      authType,
      pinned,
    });
    return userObjWithAuth;
  });

  const ret: ProjectObject = {
    ...rdbProject,
    Users,
  };
  return ret;
};

export const ProjectPureObjectSchema = withAccessTime()
  .addField("id", T.string())
  .addField("name", T.string())
  .addField("WorkspaceId", T.string(), false)
  .addField("emoji_lbl", T.string(), false)
  .addField("researchName", T.string(), false)
  .addField("researchOwner", T.string(), false)
  .addField("researchNumber", T.string(), false)
  .addField("researchStart", T.string(), false)
  .addField("researchEnd", T.string(), false)
  .addField("lock_delete", T.boolean(), false)
  .addField("lock_download", T.boolean(), false);

export const ProjectDBObjectSchema = ProjectPureObjectSchema.clone()
  .addField(
    "Users",
    T.array(
      UserObjectSchema.clone().addField(
        "User_Project_Map",
        UserProjectMapObjectSchema.clone()
      )
    )
  )
  .addField("deactivated", T.boolean(), false);

export const ProjectObjectSchema = ProjectPureObjectSchema.clone().addField(
  "Users",
  T.array(UserObjectWithAuthTypeSchema.clone())
);

export const extractProjectPureObject = T.mkObjectExtractor<ProjectPureObject>(
  ProjectPureObjectSchema
);
export const extractProjectObject =
  T.mkObjectExtractor<ProjectObject>(ProjectObjectSchema);
export const validateProjectObject =
  T.mkValidator<ProjectObject>(ProjectObjectSchema);
export const extractProjectDBObject = T.mkObjectExtractor<ProjectDBObject>(
  ProjectDBObjectSchema
);
