import React from "react";
import { Box } from "@material-ui/core";
import GoonoTypography from "@ui/goono/components/typo/GoonoTypography";
import { useServiceSidebarStyles } from "./styles/index";

export default function Footer() {
  const classes = useServiceSidebarStyles();
  return (
    <Box className={classes.bottom_container}>
      <GoonoTypography
        type="b2_m"
        className="version"
        children="Goono ver 3.4"
      />
    </Box>
  );
}
