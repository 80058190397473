import React from "react";
import { useLandingHeaderStyles } from "./styles/LandingHeader";
import { Box } from "@material-ui/core";
import Flex from "@ui/goono/components/layouts/Flex";
import SolidButton from "@ui/goono/components/buttons/SolidButton";
import HomeRoundedIcon from "@material-ui/icons/HomeRounded";
import CommonHeaderLogo from "./CommonHeaderLogo";
import { useHistory } from "react-router-dom";
import { ScreenURL } from "src/routes/route_list";

export default function LandingHeader() {
  const classes = useLandingHeaderStyles();
  const history = useHistory();

  const goSignIn = () => history.push(ScreenURL.SIGN_IN);
  const goGoonoService = () => window.open("https://www.goono.so/");

  return (
    <Box className={classes.landing_header_root}>
      <Flex container className={classes.landing_header_box}>
        <CommonHeaderLogo onClick={goSignIn} />
        <SolidButton
          startIcon={<HomeRoundedIcon />}
          children="구노 홈 바로가기"
          onClick={goGoonoService}
        />
      </Flex>
    </Box>
  );
}
