import React from "react";
import GoonoThemeProvider, {
  GoonoTheme,
} from "@ui/goono/styles/GoonoThemeProvider";
import { ThemeProvider as EmotionThemeProvider } from "@emotion/react";
import GoonoRoutingManager from "./routes/GoonoRoutingManager";
import ContainerProvider from "./redux/container";
import { BrowserRouter } from "react-router-dom";
import { persistor, store } from "./redux/store";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";
import { PersistGate } from "redux-persist/integration/react";
import { ENV, changeEnv } from "@goono-react-commons/services/api";

export default function App() {
  /** init env */
  React.useEffect(() => {
    const test_mode = process.env.REACT_APP_TEST_ENABLED?.toLocaleLowerCase();
    const app_api_server = process.env.REACT_APP_API_SERVER;
    const app_ipfs_api_server = process.env.REACT_APP_IPFS_API_SERVER;

    if (
      typeof test_mode === "string" &&
      (test_mode === "1" || test_mode === "y")
    ) {
      changeEnv("staging");
      if (typeof app_api_server === "string") ENV.API_SERVER = app_api_server;
      if (typeof app_ipfs_api_server === "string")
        ENV.IPFS_API_SERVER = app_ipfs_api_server;
      console.log("ENV CHANGED: ", ENV);
    }
  }, []);

  return (
    <ServiceProvider>
      <GoonoRoutingManager />
    </ServiceProvider>
  );
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
    },
  },
});

function ServiceProvider(props: { children: React.ReactChild }) {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <EmotionThemeProvider theme={GoonoTheme}>
            <GoonoThemeProvider>
              <ContainerProvider>
                <BrowserRouter>{props.children}</BrowserRouter>
              </ContainerProvider>
            </GoonoThemeProvider>
          </EmotionThemeProvider>
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  );
}
