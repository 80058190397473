import { useGetOrganizationQuery } from "@react-query/organization";
import { useHistory } from "react-router-dom";
import { ScreenURL } from "src/routes/route_list";

export const useOrganizationInfo = () => {
  const organizationQuery = useGetOrganizationQuery();
  const history = useHistory();

  if (organizationQuery.isSuccess !== true) {
    history.push(ScreenURL.SIGN_IN);
    throw Error(`organization query err`);
  }

  return organizationQuery.data;
};
