import React from "react";
import { Switch, Route } from "react-router-dom";
import { CommonRoute, LandingRoute, PrivateRoute } from "./routeByType";
import { routes_commons, routes_landing, routes_service } from "./path";
import { Grid } from "@material-ui/core";

export default function RoutingContents() {
  return (
    <Grid container>
      <Switch>
        {routes_service.map((route, id) => (
          <PrivateRoute {...route} key={`prevateRoute${id}`} />
        ))}
        {routes_landing.map((route, id) => (
          <LandingRoute {...route} key={`landingRoute${id}`} />
        ))}
        {routes_commons.map((route, id) => (
          <CommonRoute {...route} key={`commonRoute${id}`} />
        ))}
        <Route path="/*" component={Grid} />
      </Switch>
    </Grid>
  );
}
