import {
  Organization,
  OrganizationAdmin,
  OrganizationAdminSchema,
  OrganizationSchema,
} from "../organization";
import { Workspace, WorkspaceSchema } from "../workspace";
import T from "@redwit-commons/utils/typecheck";

export type OGetLoginOrganization = {
  accessToken: string;
  userInfo: OrganizationAdmin;
};

export type OGetOrganizationWorkspaceList = {
  workspaceList: Workspace[];
};

export const OGetLoginOrganizationSchema = T.object()
  .addField("accessToken", T.string())
  .addField("userInfo", OrganizationAdminSchema.clone());

export const validateOGetLoginOrganization =
  T.mkValidator<OGetLoginOrganization>(OGetLoginOrganizationSchema);

export const validateOGetOrganization =
  T.mkValidator<Organization>(OrganizationSchema);

export const validateOGetOrganizationWorkspaceList = T.mkValidator<Workspace[]>(
  T.array(WorkspaceSchema)
);

export const validateOGetOrganizationAdmin = T.mkValidator<OrganizationAdmin>(
  OrganizationAdminSchema
);
