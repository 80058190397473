import { makeStyles } from "@material-ui/core";

export const useLandingHeaderStyles = makeStyles(() => ({
  landing_header_root: {
    position: "fixed",
    top: 30,
    width: "100%",
  },
  landing_header_box: {
    padding: "0 36px",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));
