import React from "react";
import { useSelector } from "react-redux";
import loadable from "@loadable/component";
import { RootState } from "src/redux/store/reducers";
import { ModalInfo, ModalType } from "src/redux/store/reducers/modal";

const PasswordModifyModal = loadable(
  () => import("src/modals/PasswordModifyModal")
);

export default function GoonoModalManager() {
  const modalState = useSelector((state: RootState) => state.modal).state;

  const renderModalContent = (modalInfo: ModalInfo) => {
    switch (modalInfo.type) {
      case ModalType.PASSWORD_MODIFY:
        return <PasswordModifyModal />;
    }
  };

  return (
    <React.Fragment>
      {modalState.openedModalList.map((modalInfo) => (
        <React.Fragment key={modalInfo.type}>
          {renderModalContent(modalInfo)}
        </React.Fragment>
      ))}
    </React.Fragment>
  );
}
