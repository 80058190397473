import T from "@redwit-commons/utils/typecheck";

/**
 * IPFS 에 저장된 파일 정보
 */
export type IpfsFile = {
  /**
   * 파일 또는 폴더의 CID
   */
  readonly cid: string;
  /**
   * 파일 또는 폴더 생성 시각
   */
  readonly createdAt?: number;
  /**
   * 파일의 확장자가 주어진 경우
   */
  readonly extension?: string;
  /**
   * 파일을 암호화 한 키의 정보가 주어진 경우
   */
  readonly CipherId?: string;
};

export const IpfsFileSchema = T.object()
  .addField("cid", T.string())
  .addField("createdAt", T.number(), false)
  .addField("extension", T.string(), false)
  .addField("CipherId", T.string(), false);

export const validateIpfsFile = T.mkValidator<IpfsFile>(IpfsFileSchema);

/// Cid 이름이 다른 경우 일관되게 매핑하는 유틸리티 함수 모음

/**
 * IPFS 에 저장된 데이터 정의 (암호화 및 비 암호화 모두 포함)
 */
export type IpfsData = {
  cid: string;
  extension: string;
  CipherId?: string;
};

export const IpfsDataSchema = T.object()
  .addField("cid", T.string())
  .addField("extension", T.string())
  .addField("CipherId", T.string(), false);

export const validateIpfsData = T.mkValidator<IpfsData>(IpfsDataSchema);

/**
 * IpfsData 형식 비교
 * @param from
 * @returns
 */
export const compareIpfsData = (a: IpfsData, b: IpfsData) => {
  return (
    a.cid === b.cid && a.extension === b.extension && a.CipherId === b.CipherId
  );
};

/**
 * Cid 기본 형태
 * @param from
 * @returns
 */
export const extractCid = (from: {
  cid: string;
  extension: string;
  CipherId?: string;
}) => {
  return {
    cid: from.cid,
    extension: from.extension,
    CipherId: from.CipherId,
  };
};

/**
 * profile cid
 * @param from
 * @returns
 */
export const extractProfile = (from: {
  profile_cid: string;
  profile_extension: string;
  CipherId?: string;
}): IpfsData => {
  return {
    cid: from.profile_cid,
    extension: from.profile_extension,
    CipherId: from.CipherId,
  };
};

/**
 * sign cid
 * @param from
 * @returns
 */
export const extractSign = (from: {
  user_sign_cid?: string;
  user_sign_extension?: string;
  CipherId?: string;
}): IpfsData | undefined => {
  if (
    from.user_sign_cid === undefined ||
    from.user_sign_extension === undefined
  ) {
    return undefined;
  }
  return {
    cid: from.user_sign_cid,
    extension: from.user_sign_extension,
    CipherId: from.CipherId,
  };
};

/**
 * original cid
 * @param from
 * @returns
 */
export const extractOriginal = (from: {
  originalCid: string;
  originalExtension: string;
  CipherId?: string;
}): IpfsData => {
  return {
    cid: from.originalCid,
    extension: from.originalExtension,
    CipherId: from.CipherId,
  };
};

/**
 * thumb cid
 * @param from
 * @returns
 */
export const extractThumb = (from: {
  thumbCid?: string;
  thumbExtension?: string;
  CipherId?: string;
}): IpfsData | undefined => {
  if (from.thumbCid === undefined || from.thumbExtension === undefined) {
    return undefined;
  }
  return {
    cid: from.thumbCid,
    extension: from.thumbExtension,
    CipherId: from.CipherId,
  };
};
