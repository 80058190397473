import React from "react";
import { IconButton, FormLabel } from "@material-ui/core";
import useStyles from "./styles/IconOnlyButton";
import clsx from "clsx";
export type ButtonSize = "xl" | "lg" | "md" | "sm";
type ButtonType =
  | "gray"
  | "gray_line"
  | "cool_line"
  | "primary"
  | "primary_line"
  | "gray_fill";

export type IconOnlyButtonProps = {
  customId?: string;
  /** 버튼 컬러 타입 : default만 지정된 상태*/
  buttonSize?: ButtonSize;
  /** 버튼 크기 */
  buttonType?: ButtonType;
  radius?: "rect" | "circle";
  /** 비활성화 */
  disabled?: boolean;
  className?: string;
  dataTestid?: string;
  onClick?: (evt: React.MouseEvent<HTMLElement>) => void;
  /** form label for 설정 */
  htmlFor?: string;
  /** 아이콘 Node */
  children: React.ReactNode;
  style?: React.CSSProperties;
  ariaLabel?: string;
};

/**
 * - 단일 아이콘 버튼
 */
const IconOnlyButton: React.FC<IconOnlyButtonProps> = (
  props: IconOnlyButtonProps
) => {
  const {
    customId,
    buttonSize,
    buttonType,
    radius,
    disabled,
    className,
    dataTestid,
    onClick,
    htmlFor,
    children,
    style,
    ariaLabel,
  } = props;

  const classes = useStyles();

  const getSizeClass = () => {
    switch (buttonSize) {
      case "xl":
        return [classes.btn_xl];
      case "lg":
        return [classes.btn_lg];
      case "md":
        return [classes.btn_md];
      case "sm":
        return [classes.btn_sm];
      default:
        return [classes.btn_lg];
    }
  };

  const getColorClass = () => {
    switch (buttonType) {
      case "gray":
        return [classes.gray];
      case "gray_fill":
        return [classes.gray_fill];
      case "gray_line":
        return [classes.gray_line];
      case "cool_line": {
        return [classes.cool_line];
      }
      case "primary": {
        return [classes.primary];
      }
      case "primary_line": {
        return [classes.primary_line];
      }
      default:
        return [classes.gray];
    }
  };
  const component =
    htmlFor !== undefined && htmlFor && (disabled ?? true)
      ? { component: FormLabel, htmlFor }
      : {};

  return (
    <IconButton
      {...component}
      disableRipple
      id={customId}
      data-testid={dataTestid}
      disabled={disabled}
      onClick={onClick}
      className={clsx(
        classes.btn_base,
        radius === "rect" && classes.btn_rect,
        className,
        ...getSizeClass(),
        ...getColorClass()
      )}
      style={style}
      aria-label={ariaLabel}
    >
      {children}
    </IconButton>
  );
};

IconOnlyButton.defaultProps = {
  buttonType: "gray",
  buttonSize: "lg",
};

export default IconOnlyButton;
