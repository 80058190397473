import { Action as ReduxAction } from "redux";
import {
  HasErrorKind,
  InternalError,
  InternalErrorKind,
} from "@redwit-commons/utils/exception2";
import { getLoggers } from "@redwit-commons/utils/log";
import { PERSIST as PERSIST_ACTION_NAME } from "redux-persist";

const { INFO, WARN, ERROR } = getLoggers("StateMachine3");

// AtomicObject from Immer
// export type PossibleEnumType = Function | Promise<any> | Date | RegExp | Boolean | Number | String;
// Enum 에 다양한 값이 사용될 수 있지만 일단은 String 만 허용.
export type Enum = string;

// StateMachine 끼리 구분할 때 쓰이는 ID의 타입. 현재는 string.
// 만약 이를 변경하고자 한다면 아래에 isStateAction 의 타입 추론 부분도 변형해야함.
export type StateMachineID = string;

// 정적인 State 를 나타냄. status 필드 이외에 state에 다른 값을 저장할 수 있음.
export type State<E extends Enum> = {
  readonly status: E;
};

// 사용자 정의 Action 을 나타냄. kind 이외에도 다양한 action 의 매개 변수를 저장할 수 있음.
export type Action<E extends Enum> = {
  readonly kind: E;
};

// StateMachine3 에서는 위의 Action(Try) 뿐만 아니라 Finish, Reset 등의 action 도 포함함. 이를 모두 포함하는 enum 타입.
export enum StateMachineActionType {
  TRY = "TRY",
  FINISH = "FINISH",
  CANCEL = "CANCEL",
  RESET = "RESET",
}

// 여러 state machine 및 다른 reducer 사이에서 action 을 구분하는 정보.
export type ActionID = {
  readonly type: "StateMachineAction3";
  readonly id: StateMachineID;
};

// FinishAction 은 actionType 과 함께 목적지 toState를 저장.
export type FinishAction<SE extends Enum, S extends State<SE>> = ActionID & {
  readonly actionType: StateMachineActionType.FINISH;
  readonly toState: S;
};

// CancelAction 은 action type 만 기억하고 다른 정보 필요 없음. 현재 TryAction 을 취소시킴.
export type CancelAction = ActionID & {
  readonly actionType: StateMachineActionType.CANCEL;
};

// ResetAction 은 action type 만 기억하고 다른 정보 필요 없음. (state machine 에 어디가 init state 인지 정보가 있음.)
export type ResetAction = ActionID & {
  readonly actionType: StateMachineActionType.RESET;
};

// TryAction (사용자 입장에서 일반 Action)
// Action 정보와 함께 resolve, reject 시 수행해야 할 action 들도 저장.
export type TryAction<
  AE extends Enum,
  A extends Action<AE>,
  Err extends HasErrorKind
> = ActionID & {
  readonly actionType: StateMachineActionType.TRY;
  readonly tryAction: A;
  readonly onResolve: () => void;
  readonly onReject: (err: Err | InternalError) => void;
};

// 위 세 action 의 union type
export type StateMachineAction<
  SE extends Enum,
  S extends State<SE>,
  AE extends Enum,
  A extends Action<AE>,
  Err extends HasErrorKind
> = FinishAction<SE, S> | CancelAction | ResetAction | TryAction<AE, A, Err>;

// State machine 그래프에서 edge에 해당하는 정보. 추가 정보 필드는 제거하고 오직 Enum 정보만 가지고 그래프 구성.
export type StateTransition<SE extends Enum, AE extends Enum> = {
  readonly fromStatus: SE;
  readonly toStatus: SE;
  readonly actionKind: AE;
};

const ErrorPrefix = "__sm3_error_prefix::";

export function transition<SE extends Enum, AE extends Enum>(
  fromStatus: SE,
  toStatus: SE,
  actionKind: AE
): StateTransition<SE, AE> {
  return { fromStatus, toStatus, actionKind };
}

export class StateMachine3<
  SE extends Enum,
  S extends State<SE>,
  AE extends Enum,
  A extends Action<AE>,
  Err extends HasErrorKind
> {
  /**
   * actionTable
   * fromState => list of [toState, action]
   */
  _actionTable: Map<SE, Set<[SE, AE]>>;
  _id: StateMachineID;
  _initState: S;
  _listofActions: Set<AE>;
  _listofStates: Set<SE>;
  constructor(
    id: StateMachineID,
    initState: S,
    actions: readonly StateTransition<SE, AE>[],
    failure?: { failState: SE; failAction: AE }
  ) {
    this._actionTable = new Map();
    this._id = id;
    this._initState = initState;
    this._listofActions = new Set();
    this._listofStates = new Set();

    /* actionTable 채우기 */
    actions.forEach((_) => {
      this._listofActions.add(_.actionKind);
      let actionSet = this._actionTable.get(_.fromStatus);
      if (actionSet === undefined) {
        actionSet = new Set();
        this._actionTable.set(_.fromStatus, actionSet);
      }
      actionSet.add([_.toStatus, _.actionKind]);
      this._listofStates.add(_.fromStatus);
      this._listofStates.add(_.toStatus);
    });

    /* 모든 state 로 부터 failure state 로 가는 길 만들어주기 */
    if (failure === undefined) {
      return;
    }
    // 만약 failure -> failure 로 가는 사이클을 막고 싶으면 아래 두 코드를 맨 밑으로 보내면 됨.
    this._listofActions.add(failure.failAction);
    this._listofStates.add(failure.failState);

    this._listofStates.forEach((fromStatus) => {
      let actionSet = this._actionTable.get(fromStatus);
      if (actionSet === undefined) {
        actionSet = new Set();
        this._actionTable.set(fromStatus, actionSet);
      }
      actionSet.add([failure.failState, failure.failAction]);
    });
  }

  getTestPaths(): AE[][] {
    const initState = this.getInitState();
    const actionTable = new Map(this._actionTable);
    const paths: AE[][] = [];
    const actionSet = actionTable.get(initState.status);
    if (actionSet === undefined) {
      return paths;
    }
    for (const entry of actionSet) {
      const visit: StateTransition<SE, AE>[] = [];
      const stack: StateTransition<SE, AE>[] = [];
      const initialPath = {
        fromStatus: initState.status,
        toStatus: entry[0],
        actionKind: entry[1],
      };
      stack.push(initialPath);
      for (let i = 0; i < 100; i++) {
        const node = stack.pop();
        if (node === undefined) {
          paths.concat(visit.map((val) => val.actionKind));
          break;
        }
        const filters = visit.filter(
          (v) =>
            node !== undefined &&
            v.actionKind === node.actionKind &&
            v.fromStatus === node.fromStatus &&
            v.toStatus === node.toStatus
        );
        if (filters.length < 1) {
          visit.push(node);
          // get next node state
          const nextActionSet = actionTable.get(node.toStatus);
          if (nextActionSet === undefined) {
            paths.concat(visit.map((val) => val.actionKind));
            break;
          }
          for (const set of nextActionSet) {
            const nextNode = {
              fromStatus: node.toStatus,
              toStatus: set[0],
              actionKind: set[1],
            };
            stack.push(nextNode);
          }
        }
      }
      ERROR("Path 길이가 제한 범위를 초과");
      paths.push(visit.map((val) => val.actionKind));
    }

    return paths;
  }

  isStateAction(
    input: ReduxAction
  ): input is StateMachineAction<SE, S, AE, A, Err> {
    // Note: StateMachineID 의 타입이 변하면 여기도 변해야함.
    if (
      !(typeof input.type === "string" && input.type === "StateMachineAction3")
    ) {
      return false;
    }
    const input2 = input as ActionID;
    if (input2.id !== this._id) {
      return false;
    }
    return true;
  }

  isTryAction(
    input: StateMachineAction<SE, S, AE, A, Err>
  ): input is TryAction<AE, A, Err> {
    return input.actionType === StateMachineActionType.TRY;
  }

  isFinishAction(
    input: StateMachineAction<SE, S, AE, A, Err>
  ): input is FinishAction<SE, S> {
    return input.actionType === StateMachineActionType.FINISH;
  }

  isResetAction(
    input: StateMachineAction<SE, S, AE, A, Err>
  ): input is ResetAction {
    return input.actionType === StateMachineActionType.RESET;
  }

  isCancelAction(
    input: StateMachineAction<SE, S, AE, A, Err>
  ): input is CancelAction {
    return input.actionType === StateMachineActionType.CANCEL;
  }

  isStateError(input: unknown): input is Err {
    if (input === undefined) return false;
    /* eslint-disable @typescript-eslint/no-explicit-any */
    const input2 = input as any;
    /* eslint-enable @typescript-eslint/no-explicit-any */
    if (input2.__sm3_error_tag !== ErrorPrefix + this._id) {
      return false;
    }
    return typeof input2.kind === "string";
  }

  mkStateError(input: Err): Err & { __sm3_error_tag: string } {
    return { ...input, __sm3_error_tag: ErrorPrefix + this._id };
  }

  getInitState(): S {
    return this._initState;
  }

  checkTryAction(prevState: S, input: TryAction<AE, A, Err>): boolean {
    const possibleActions = this._actionTable.get(prevState.status);
    if (possibleActions !== undefined) {
      for (const [, action] of possibleActions) {
        if (action === input.tryAction.kind) return true;
      }
    }
    return false;
  }

  checkFinishAction(
    prevState: S,
    prevAction: TryAction<AE, A, Err>,
    input: S
  ): boolean {
    const possibleActions = this._actionTable.get(prevState.status);
    if (possibleActions !== undefined) {
      for (const [toState, action] of possibleActions) {
        if (action === prevAction.tryAction.kind) {
          if (toState === input.status) return true;
        }
      }
    }
    return false;
  }

  newTryAction(
    action: A,
    onResolve: () => void = () => {
      return;
    },
    onReject: (err: Err | InternalError) => void = () => {
      return;
    }
  ): TryAction<AE, A, Err> {
    return {
      type: "StateMachineAction3",
      id: this._id,
      actionType: StateMachineActionType.TRY,
      tryAction: action,
      onResolve,
      onReject,
    };
  }

  newFinishAction(toState: S): FinishAction<SE, S> {
    return {
      type: "StateMachineAction3",
      id: this._id,
      actionType: StateMachineActionType.FINISH,
      toState,
    };
  }

  newCancelAction(): CancelAction {
    return {
      type: "StateMachineAction3",
      id: this._id,
      actionType: StateMachineActionType.CANCEL,
    };
  }

  // Reset 에는 onResolve 를 달지 않습니다. 어떠한 경우에도 강제로 INIT state 로 만드는 작업이라..
  // 만약에 Init state 로 가는 작업을 추적하고 싶으면 (resolve/reject)
  // 명시적으로 TryAction 을 만들어주세요.
  newResetAction(): ResetAction {
    return {
      type: "StateMachineAction3",
      id: this._id,
      actionType: StateMachineActionType.RESET,
    };
  }
}
/// TODO 여기부터
export type ReduxStateType<
  SE extends Enum,
  S extends State<SE>,
  AE extends Enum,
  A extends Action<AE>,
  Err extends HasErrorKind
> = {
  readonly state: S;
  readonly action?: TryAction<AE, A, Err>;
};

export type ReducerType<
  SE extends Enum,
  S extends State<SE>,
  AE extends Enum,
  A extends Action<AE>,
  Err extends HasErrorKind
> = (
  state: ReduxStateType<SE, S, AE, A, Err> | undefined,
  action: ReduxAction
) => ReduxStateType<SE, S, AE, A, Err>;

export function mkReducer<
  SE extends Enum,
  S extends State<SE>,
  AE extends Enum,
  A extends Action<AE>,
  Err extends HasErrorKind
>(
  stateMachine: StateMachine3<SE, S, AE, A, Err>
): ReducerType<SE, S, AE, A, Err> {
  return (
    state: ReduxStateType<SE, S, AE, A, Err> | undefined,
    action: ReduxAction
  ): ReduxStateType<SE, S, AE, A, Err> => {
    if (state === undefined) {
      return { state: stateMachine.getInitState(), action: undefined };
    }
    if (action.type === PERSIST_ACTION_NAME) {
      if (state.action !== undefined) {
        WARN(
          `${stateMachine._id} =>`,
          "PersistInit: Removing previous tryAction"
        );
        return {
          ...state,
          action: undefined,
        };
      } else {
        return state;
      }
    }
    if (!stateMachine.isStateAction(action)) {
      // 내 action 이 아님, 무시.
      return state;
    }
    if (stateMachine.isTryAction(action)) {
      if (state.action !== undefined && state.action.id !== action.id) {
        WARN(
          `${stateMachine._id}::${state.state.status}, ${state.action.tryAction.kind} => ${action.tryAction.kind}`,
          "consequent try action, ignore second try"
        );
        try {
          action.onReject({
            kind: InternalErrorKind.Abort,
            loc: `${stateMachine._id} reducer`,
            msg: "consequent try action, ignore second try",
            prevAction: state.action,
            action,
          } as InternalError);
        } catch (err) {
          ERROR("Fatal error while rejecting tryAction", err);
        }

        return state;
      }
      if (!stateMachine.checkTryAction(state.state, action)) {
        ERROR(
          `${stateMachine._id}::${state.state.status} => ${action.tryAction.kind}`,
          "invalid action, ignore this."
        );
        try {
          action.onReject({
            kind: InternalErrorKind.Abort,
            loc: `${stateMachine._id} reducer`,
            msg: "invalid action, ignore this.",
            action,
          } as InternalError);
        } catch (err) {
          ERROR("Fatal error while rejecting tryAction2", err);
        }
        return state;
      }

      INFO(
        `${stateMachine._id}::${state.state.status} => ${action.tryAction.kind}`
      );
      return {
        state: state.state,
        action,
      };
    } else if (stateMachine.isFinishAction(action)) {
      if (state.action === undefined) {
        ERROR(
          `${stateMachine._id}::${state.state.status} => ${action.toState.status}`,
          "try to finish without tryAction. Cancel."
        );
        return state;
      }
      if (
        !stateMachine.checkFinishAction(
          state.state,
          state.action,
          action.toState
        )
      ) {
        ERROR(
          `${stateMachine._id}::${state.state.status}, ${state.action.tryAction.kind} => ${action.toState.status}`,
          "invalid finishState. Cancel."
        );
        try {
          state.action.onReject({
            kind: InternalErrorKind.Abort,
            loc: `${stateMachine._id} reducer`,
            msg: "invalid finishState. Cancel.",
            action,
          } as InternalError);
        } catch (err) {
          ERROR("Fatal error while rejecting finishAction", err);
        }
        return state;
      }
      INFO(
        `${stateMachine._id}::${state.state.status}, ${state.action.tryAction.kind} => ${action.toState.status}`
      );
      return {
        state: action.toState,
        action: undefined,
      };
    } else if (stateMachine.isCancelAction(action)) {
      if (state.action === undefined) {
        ERROR(
          `${stateMachine._id}::${state.state.status}`,
          "try to cancel without tryAction. Cancel."
        );
        return state;
      }

      INFO(
        `${stateMachine._id}::${state.state.status} => ${state.action.tryAction.kind}`,
        "Cancel this action."
      );
      return {
        state: state.state,
        action: undefined,
      };
    } else if (stateMachine.isResetAction(action)) {
      INFO(`${stateMachine._id}::${state.state.status}, ... => reset now`);
      return {
        state: stateMachine.getInitState(),
        action: undefined,
      };
    } else {
      ERROR("Unknown action:", action);
      return state;
    }
  };
}
