import { useGetOrganizationAdminMutation } from "@react-query/organization";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/redux/store/reducers";
import {
  UserStateStatus,
  doUserActionAsync,
  UserActionKind,
} from "src/redux/store/reducers/user";

export const useUserProfileRefresher = () => {
  const dispatch = useDispatch();
  const userState = useSelector((state: RootState) => state.user).state;
  const getOrganizationAdminMutation = useGetOrganizationAdminMutation();

  const userToken =
    userState.status === UserStateStatus.SUCCESS
      ? userState.userInfo.token
      : ``;

  const refreshProfile = async () => {
    if (userState.status === UserStateStatus.INIT) return;

    const freshProfile = await getOrganizationAdminMutation.mutateAsync(
      userToken
    );

    await doUserActionAsync(dispatch, {
      kind: UserActionKind.REGIST_USER_INFO,
      userInfo: {
        ...freshProfile,
        token: userToken,
      },
    });
  };

  useEffect(() => {
    refreshProfile().catch(console.error);
  }, []);
};
