import { CircularProgress } from "@material-ui/core";
import React, { CSSProperties } from "react";

export default function CircularLoadingView(props: {
  loadingSize?: number;
  style?: CSSProperties;
}) {
  return (
    <div
      style={{
        width: `100%`,
        height: `100%`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...props.style,
      }}
    >
      <CircularProgress size={props.loadingSize ?? 60} />
    </div>
  );
}
