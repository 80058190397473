import { IpfsData, IpfsDataSchema } from "../api/object/ipfs";
import T from "@redwit-commons/utils/typecheck";

export type Workspace = {
  id: string;
  name: string;
  members: WorkspaceMemberInfo[];
  pendingMembers: WorkspacePendingMemberInfo[];
  createdAt: string;
  /** 워크스페이스 썸네일 이미지, 없을 경우 워크스페이스 명 앞글자로 대체 */
  thumbnail?: IpfsData;
  thumbColor?: string;
  /** 워크스페이스에 플랜이 없을 수 있음 */
  plan?: WorkspacePlan;
};

export type WorkspacePendingMemberInfo = {
  email: string;
  role: WorkspaceRoleKind;
  createdAt: string;
};

export type WorkspaceMemberInfo = WorkspacePendingMemberInfo & {
  id: string;
  name: string;
  institution: string;
  department: string;
  profile_cid: string;
  profile_extension: string;
  CipherId?: string;
};

export type UserLatestVisitLog = {
  browser: string;
  ip: string;
  os: string;
  timestamp: string;
};

export enum WorkspaceRoleKind {
  /** 소유자 */
  OWNER = "Workspace::OWNER",
  /** 관리자 */
  ADMIN = "Workspace::ADMIN",
  /** 연구원 */
  RESEARCHER = "Workspace::RESEARCHER",
  /** 기존 외부 연구원 */
  EXTERNAL_RESEARCHER = "Workspace::EXTERNAL_RESEARCHER",
}

export type WorkspacePlan = {
  name: string;
  type: WorkspacePlanKind;
  feature: WorkspacePlanFeatures;
  seats: number;
  period: WorkspacePlanPeriod;
  price: string;
  description: string;
  aclOption?: WorkspacePlanACLOptions;
};

export type WorkspacePlanACLOptions = {
  enableACL?: boolean;
  applyAdmin?: boolean;
};

export type WorkspacePlanPeriod = {
  startAt: string;
  endAt: string;
};

export type WorkspacePlanFeatures = {
  researchNote: boolean;
  dataRoom: boolean;
};

export enum WorkspacePlanKind {
  /**@description 개인 데이터룸 플랜 */
  PERSONAL = "WorkspacePlan::PERSONAL",
  TEAM = "WorkspacePlan::TEAM",
  TRIAL = "WorkspacePlan::TRIAL",
  ENTERPRISE = "WorkspacePlan::ENTERPRISE",
  ENTERPRISE_PLUS = "WorkspacePlan::ENTERPRISE_PLUS",
}

export enum WorkspaceFilterType {
  FREE = "free",
  TRIAL = "trial",
  PERSONAL = "personal",
  TEAM = "team",
  ENTERPRISE = "enterprise",
  ENTERPRISE_PLUS = "enterprise_plus",
}
export enum WorkspacePlanServiceEnum {
  GOONO = "goono",
  DATAROOM = "dataroom",
}

export enum WorkspaceMemberRoleFilterType {
  OWNER = "owner",
  ADMIN = "admin",
  RESEARCHER = "researcher",
  EXTERNAL_RESEARCHER = "external_researcher",
}

export type WorkspaceMemberAccessLog = {
  timestamp: string;
  ip: string;
  browser: string;
  location: string;
  userName: string;
  userProfile: IpfsData;
};

/** ---- schema ---- */
export const WorkspacePlanSchema = T.object()
  .addField("name", T.string())
  .addField(
    "type",
    T.string().withEnum([
      WorkspacePlanKind.PERSONAL,
      WorkspacePlanKind.TEAM,
      WorkspacePlanKind.TRIAL,
      WorkspacePlanKind.ENTERPRISE,
      WorkspacePlanKind.ENTERPRISE_PLUS,
    ])
  )
  .addField(
    "feature",
    T.object()
      .addField("researchNote", T.boolean())
      .addField("dataRoom", T.boolean())
  )
  .addField("seats", T.number())
  .addField(
    "period",
    T.object().addField("startAt", T.string()).addField("endAt", T.string())
  )
  .addField("price", T.string())
  .addField("description", T.string())
  .addField(
    "aclOption",
    T.object()
      .addField("enableACL", T.boolean(), false)
      .addField("applyAdmin", T.boolean(), false),
    false
  );

export const WorkspaceMemberRoleSchema = T.string().withEnum([
  WorkspaceRoleKind.OWNER,
  WorkspaceRoleKind.ADMIN,
  WorkspaceRoleKind.RESEARCHER,
  WorkspaceRoleKind.EXTERNAL_RESEARCHER,
]);

export const WorkspaceMemberVisitLogSchema = T.object()
  .addField("browser", T.string())
  .addField("ip", T.string())
  .addField("os", T.string())
  .addField("timestamp", T.string());

export const WorkspaceMemberInfoSchema = T.object()
  .addField("id", T.string())
  .addField("name", T.string())
  .addField("email", T.string())
  .addField("role", WorkspaceMemberRoleSchema.clone())
  .addField("institution", T.string())
  .addField("department", T.string())
  .addField("createdAt", T.string())
  .addField("profile_cid", T.string())
  .addField("profile_extension", T.string())
  .addField("CipherId", T.string(), false);

export const WorkspacePendingMemberInfoSchema = T.object()
  .addField("email", T.string())
  .addField("role", WorkspaceMemberRoleSchema.clone())
  .addField("createdAt", T.string());

export const WorkspaceSchema = T.object()
  .addField("id", T.string())
  .addField("name", T.string())
  .addField("members", T.array(WorkspaceMemberInfoSchema.clone()))
  .addField("pendingMembers", T.array(WorkspacePendingMemberInfoSchema.clone()))
  .addField("createdAt", T.string())
  .addField("plan", WorkspacePlanSchema.clone(), false)
  .addField("thumbColor", T.string(), false)
  .addField("thumbnail", IpfsDataSchema.clone(), false);

export const WorkspaceMemberAccessLogSchema = T.object()
  .addField("timestamp", T.string())
  .addField("ip", T.string())
  .addField("browser", T.string())
  .addField("location", T.string())
  .addField("userName", T.string())
  .addField("userProfile", IpfsDataSchema.clone());
