import T from "@redwit-commons/utils/typecheck";
import { AccessTime, withAccessTime } from "./access_time";
import { ProjectObject, ProjectObjectSchema } from "./project";

export type GithubObjectCore = {
  readonly id: string;
  readonly repo: string;
  readonly token: string;
  readonly UserId: string;
  readonly FolderId?: string;
  readonly ProjectId: string;
};

export type GithubObject = GithubObjectCore & AccessTime;
export const GithubObjectSchema = withAccessTime()
  .addField("id", T.string())
  .addField("repo", T.string())
  .addField("token", T.string())
  .addField("UserId", T.string())
  .addField("FolderId", T.string(), false)
  .addField("ProjectId", T.string());

export type LinkedGithubObject = Omit<Omit<GithubObject, "token">, "UserId">;
export const LinkedGithubObjectSchema = GithubObjectSchema.clone()
  .delField("token")
  .delField("UserId");

export const extractGithubObject =
  T.mkObjectExtractor<GithubObject>(GithubObjectSchema);
export const validateGithubObject =
  T.mkValidator<GithubObject>(GithubObjectSchema);

export type GithubLinkageObject = GithubObject & {
  Project: ProjectObject;
  filesCount: number;
  Folder?: {
    id: string;
    name: string;
  };
};
export const GithubLinkageObjectSchema = GithubObjectSchema.clone()
  .addField("Project", ProjectObjectSchema)
  .addField("filesCount", T.number())
  .addField(
    "Folder",
    T.object().addField("id", T.string()).addField("name", T.string()),
    false
  );
export const validateGithubLinkageObject = T.mkValidator<GithubLinkageObject>(
  GithubLinkageObjectSchema
);

export type ProjectInsideGithubLinkageObject = Omit<
  GithubLinkageObject,
  "Project"
> & {
  userName: string;
};
export const ProjectInsideGithubLinkageObjectSchema = GithubObjectSchema.clone()
  .addField("userName", T.string())
  .addField(
    "Folder",
    T.object().addField("id", T.string()).addField("name", T.string()),
    false
  );
export const validateProjectInsideGithubLinkageObject =
  T.mkValidator<ProjectInsideGithubLinkageObject>(
    ProjectInsideGithubLinkageObjectSchema
  );
