import T from "@redwit-commons/utils/typecheck";

/**
 * ORM 에 의해서 자동으로 생성되는 액세스 시간 정보
 */
export type AccessTime = {
  /**
   * 생성 시간
   */
  readonly createdAt: string;
  /**
   * 최종 업데이트 시간
   */
  readonly updatedAt: string;
};

export const addAccessTime = (from: T): T => {
  return from
    .addField("createdAt", T.string())
    .addField("updatedAt", T.string());
};

export const withAccessTime = (): T => {
  return addAccessTime(T.object());
};
