import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../store/reducers";
import { ReduxStateComponent3 } from "@redwit-react-commons/template/ReduxStateComponent3";
import {
  SnackbarActionKind,
  SnackBarState,
  SnackbarStateStatus,
  snackbarStateMachine,
  SnackbarStateMachineType,
  SnackBarAction,
  SnackbarType,
} from "../store/reducers/snackbar";

const mapStateToProps = (state: RootState) => {
  return {
    reduxState: state.snackbar,
  };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type SnackbarContainerProps = PropsFromRedux & {
  stateMachine: SnackbarStateMachineType;
};

export const handleSnackbarAction = (
  _prevState: SnackBarState,
  action: SnackBarAction
): SnackBarState => {
  switch (action.kind) {
    case SnackbarActionKind.TRY_OPEN: {
      if (action.isBackdrop) {
        document.body.style.overflowY = "hidden";
      } else if (
        document.body.style.overflowY === "hidden" &&
        action.type === SnackbarType.CONFIRM
      ) {
        document.body.style.overflowY = "scroll";
      }

      return {
        status: SnackbarStateStatus.OPENED,
        ...action,
      };
    }

    case SnackbarActionKind.TRY_CLOSE: {
      document.body.style.overflowY = "unset";
      return {
        status: SnackbarStateStatus.INIT,
      };
    }
  }
};

class SnackbarContainer extends ReduxStateComponent3<SnackbarContainerProps> {
  static defaultProps = { stateMachine: snackbarStateMachine };
  constructor(props: SnackbarContainerProps) {
    super(props);
  }

  protected onAction = handleSnackbarAction;
}

export default connector(SnackbarContainer);
