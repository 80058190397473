import React from "react";
import { Box, Container } from "@material-ui/core";
import { BaseGridProps } from "../BaseGrid";
import { useBaseGridStyles } from "../styles/BaseGrid";
import ServiceHeader from "src/navs/ServiceHeader";
import ServiceSidebar from "src/navs/ServiceSidebar";
import { Scrollbars } from "react-custom-scrollbars";

export default function PrivateBaseGrid(props: BaseGridProps) {
  const { viewerOption, children } = props;
  const classes = useBaseGridStyles();

  if (viewerOption.type !== "auth_page") return <></>;
  return (
    <React.Fragment>
      <ServiceHeader />
      <ServiceSidebar />
      <Box className={classes.auth_page_container}>
        <Scrollbars autoHide autoHideTimeout={300} autoHeightMax={"100%"}>
          {children}
        </Scrollbars>
      </Box>
    </React.Fragment>
  );
}

type PrivateContentContainerProps = {
  children: React.ReactNode;
};
export function PrivateContentContainer(props: PrivateContentContainerProps) {
  const classes = useBaseGridStyles();
  return (
    <Container className={classes.auth_page_content_container}>
      <>{props.children}</>
    </Container>
  );
}
