import {
  ProjectAuthType,
  ProjectAuthTypeValues,
} from "../api/object/user_project_map";
import T from "@redwit-commons/utils/typecheck";
import { SortingQueryParams } from "./sortingFilter";

export type ProjectId = string;

export type ProjectQueryParams = {
  limit: number;
  offset: number;
  projectName?: string;
  memberIds?: string; // string in format of JSON array (ex. '["a","b","c"]')
} & SortingQueryParams;
export type ProjectQueryOptions = Partial<ProjectQueryParams>;

export type ProjectSummaryDTO = {
  id: ProjectId;
  name: string;
  /**
   * @deprecated in 3.1.0
   */
  ownerUserId: string;
  members: Array<[string, ProjectAuthType]>;
  updatedAt: string;
  createdAt: string;
  totalFilesCount: number;
  WorkspaceId?: string;
};

export type ProjectDTO = {
  description: ProjectDescription;
  options: ProjectOptions;
  pinned: boolean;
} & ProjectSummaryDTO;

export type ProjectMember = {
  userId: string;
  ProjectAuthType: ProjectAuthType;
  email: string;
  name: string;
  profile_cid: string;
  profile_extension: string;
  CipherId: string | undefined;
  deactivated: boolean | undefined;
  department?: string;
  institution?: string;
};

export type AdminProjectExcelDto = {
  /** 연구노트 명 */
  projectName: string;
  /** 연구노트 과제 책임자 */
  managerName: string | undefined;
  /** 연구노트 시작일 */
  startAt: string | undefined;
  /** 연구노트 종료일 */
  endAt: string | undefined;
  /** 연구원 이름 */
  memberName: string;
  /** 연구원 소속 기관 */
  memberDepartment: string | undefined;
  /** 연구원 부서명 */
  memberInstitution: string | undefined;
};
export type AdminProjectExcelResponse = Array<{
  projectId: string;
  members: Array<AdminProjectExcelDto>;
}>;

export interface AdminProjectSummaryDTO extends ProjectSummaryDTO {
  deactivated: boolean;
}

export type AdminProjectDTO = {
  deactivated?: boolean;
  members: Array<ProjectMember>;
} & Omit<ProjectDTO, "pinned" | "members">;

export interface ProjectDescription {
  manager?: string;
  projectNumber?: string;
  startAt?: string;
  endAt?: string;
  emoji_lbl?: string;
}
export interface ProjectOptions {
  allowModification: boolean;
  allowDownload: boolean;
}

export const ProjectDescriptionSchema = T.object()
  .addField("manager", T.string(), false)
  .addField("projectNumber", T.string(), false)
  .addField("startAt", T.string(), false)
  .addField("endAt", T.string(), false)
  .addField("emoji_lbl", T.string(), false);
export const ProjectOptionsSchema = T.object()
  .addField("allowModification", T.boolean())
  .addField("allowDownload", T.boolean());

export const addProjectDescriptionFields = (from: T): T => {
  return from.addField("description", ProjectDescriptionSchema.clone());
};

export const addProjectOptionsFields = (from: T): T => {
  return from.addField("options", ProjectOptionsSchema.clone());
};

export const ProjectDTOSummarySchema = T.object()
  .addField("id", T.string())
  .addField("name", T.string())
  .addField("ownerUserId", T.string())
  .addField("members", T.array(T.array(T.string())))
  .addField("updatedAt", T.string())
  .addField("createdAt", T.string())
  .addField("totalFilesCount", T.number())
  .addField("WorkspaceId", T.string(), false);

export const ProjectDTOSchema = addProjectOptionsFields(
  addProjectDescriptionFields(
    ProjectDTOSummarySchema.clone().addField("pinned", T.boolean())
  )
);

export const ProjectMemberSchema = T.object()
  .addField("userId", T.string())
  .addField("ProjectAuthType", T.string().withEnum(ProjectAuthTypeValues))
  .addField("email", T.string())
  .addField("name", T.string())
  .addField("profile_cid", T.string())
  .addField("profile_extension", T.string())
  .addField("CipherId", T.string(), false)
  .addField("deactivated", T.boolean(), false)
  .addField("department", T.string())
  .addField("institution", T.string());

export const AdminProjectSummaryDTOSchema =
  ProjectDTOSummarySchema.clone().addField("deactivated", T.boolean());

export const AdminProjectDTOSchema = ProjectDTOSchema.clone()
  .delField("pinned")
  .delField("members")
  .addField("deactivated", T.boolean())
  .addField("members", T.array(ProjectMemberSchema.clone()));

export const AdminProjectExcelResponseSchema = T.array(
  T.object()
    .addField("projectId", T.string())
    .addField(
      "members",
      T.array(
        T.object()
          .addField(`projectName`, T.string())
          .addField(`managerName`, T.string(), false)
          .addField(`startAt`, T.string(), false)
          .addField(`endAt`, T.string(), false)
          .addField(`memberName`, T.string())
          .addField(`memberDepartment`, T.string(), false)
          .addField(`memberInstitution`, T.string(), false)
      )
    )
);
