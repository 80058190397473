import { ScreenURL } from "./route_list";
import { lazy } from "react";
import { RouteProps } from "react-router-dom";

const routes_service: Array<RouteProps> = [
  {
    path: ScreenURL.MYPAGE,
    exact: true,
    component: lazy(() => import("../screens/MyPageScreen/index")),
  },
  {
    path: ScreenURL.WORKSPACE_LIST,
    exact: true,
    component: lazy(() => import("../screens/WorkspaceListScreen/index")),
  },
  {
    path: ScreenURL.WORKSPACE_OVERVIEW,
    exact: true,
    component: lazy(() => import("../screens/WorkspaceOverviewScreen/index")),
  },
  {
    path: ScreenURL.SEARCH_RESULT,
    exact: true,
    component: lazy(() => import("../screens/SearchResultScreen")),
  },
];

const routes_landing: Array<RouteProps> = [
  {
    path: ScreenURL.SIGN_IN,
    exact: true,
    component: lazy(() => import("../screens/landing/SignInScreen/index")),
  },
  {
    path: ScreenURL.AUTH_PW,
    exact: true,
    component: lazy(
      () => import("../screens/landing/AuthPasswordScreen/index")
    ),
  },
  {
    path: ScreenURL.UPDATE_PW,
    exact: true,
    component: lazy(
      () => import("../screens/landing/UpdatePasswordScreen/index")
    ),
  },
  {
    path: ScreenURL.LANDING_CALLBACK,
    exact: true,
    component: lazy(() => import("../screens/callback/LandingCallbackScreen")),
  },
];

const routes_commons: Array<RouteProps> = [
  {
    path: ScreenURL.LOGOUT,
    exact: true,
    component: lazy(() => import("../screens/LogoutScreen")),
  },
];

export { routes_service, routes_landing, routes_commons };
