import React from "react";
import { useHistory } from "react-router-dom";
import { ScreenURL } from "src/routes/route_list";
import { SidebarMenuItemProps } from "src/navs/ServiceSidebar/SidebarMenuItem";

import { ReactComponent as ElementIcon } from "@assets/images/icon/element-equal.svg";
import { ReactComponent as ProfileIcon } from "@assets/images/icon/profile-circle.svg";

export const useCommonMenuList = () => {
  const history = useHistory();

  const common_menu_list: SidebarMenuItemProps[] = [
    {
      icon: <ElementIcon />,
      title: "조직관리",
      isSelected: window.location.pathname === ScreenURL.WORKSPACE_LIST,
      onClickMenu: () => history.push(ScreenURL.WORKSPACE_LIST),
    },
    {
      icon: <ProfileIcon />,
      title: "마이페이지",
      isSelected: window.location.pathname === ScreenURL.MYPAGE,
      onClickMenu: () => history.push(ScreenURL.MYPAGE),
    },
  ];

  return common_menu_list;
};
