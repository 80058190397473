import * as React from "react";
import { InternalErrorKind, mkErr } from "@redwit-commons/utils/exception2";
import { getLoggers } from "@redwit-commons/utils/log";
import { delay } from "@redwit-commons/utils/function";

const { WARN } = getLoggers("FutureComponent");

export interface Future {
  task: () => void | PromiseLike<void>;
  isDone?: () => boolean;
  isError?: () => boolean;
  next?: () => Future;
  except?: () => Future;
}

interface FusedFuture {
  launch: () => void;
  isDone: () => boolean;
  isError: () => boolean;
  next?: () => Future;
  except?: () => Future;
}

export class FutureComponent<
  P = Record<string, never>,
  S = Record<string, never>
> extends React.Component<P, S> {
  private isInitialized = false;
  private isUnmounted = false;
  private tasks: Array<FusedFuture> = [];
  private backgroundDelay = 3000;

  protected setBackgroundDelay(new_delay: number): void {
    this.backgroundDelay = new_delay;
  }

  protected scheduleBackgroundTask(
    task: () => boolean | PromiseLike<boolean>
  ): void {
    const waitForTask = async () => {
      for (;;) {
        try {
          const promise = new Promise<boolean>((res) => {
            this.scheduleTask(() => res(task()));
          });

          const ret = await this.guardAwait(async () => promise);
          if (!ret) break;
          await delay(this.backgroundDelay);
        } catch (err) {
          WARN("bgtask terminated by error", err);
          break;
        }
      }
    };
    void waitForTask();
  }

  componentDidMount(): void {
    this.isInitialized = true;
    this.schedule();
  }

  componentDidUpdate(_prevProps?: Readonly<P>, _prevState?: Readonly<S>): void {
    this.schedule();
  }

  componentWillUnmount(): void {
    this.isUnmounted = true;
  }

  protected async setStateAsync<K extends keyof S>(
    state:
      | ((prevState: Readonly<S>, props: Readonly<P>) => Pick<S, K> | S | null)
      | (Pick<S, K> | S | null)
  ): Promise<void> {
    return new Promise((resolve) => this.setState(state, resolve));
  }

  protected resetScheduler(): void {
    this.tasks = [];
  }

  protected schedule(): void {
    while (this._schedule());
  }

  // false: 다시 부를 필요 없음
  // true: 다시 불러줘야함.
  private _schedule(): boolean {
    if (this.tasks.length === 0 || this.isUnmounted || !this.isInitialized)
      return false;

    // 끝났는지 검사 (에러)
    if (this.tasks[0].isError()) {
      const prevFuture = this.tasks.shift(); // 1개 제거
      if (prevFuture === undefined) {
        throw mkErr({
          kind: InternalErrorKind.Fatal,
          loc: "FutureComponent::_schedule(err)",
          msg: "Shift should never return undefined (checked that len > 0)",
        });
      }
      const exceptFuture = prevFuture.except?.();
      if (exceptFuture !== undefined) {
        this.tasks.unshift(this.fuseFuture(exceptFuture));
      }
      if (this.tasks.length !== 0) {
        this.tasks[0].launch();
      }
      return true;
    }
    // 끝났는지 검사 (정상)
    if (this.tasks[0].isDone()) {
      const prevFuture = this.tasks.shift(); // 1개 제거
      if (prevFuture === undefined) {
        throw mkErr({
          kind: InternalErrorKind.Fatal,
          loc: "FutureComponent::_schedule(ok)",
          msg: "Shift should never return undefined (checked that len > 0)",
        });
      }
      const thenFuture = prevFuture.next?.();
      if (thenFuture !== undefined) {
        this.tasks.unshift(this.fuseFuture(thenFuture));
      }
      if (this.tasks.length !== 0) {
        this.tasks[0].launch();
      }
      return true;
    }
    return false;
  }

  protected async guardAwait<T>(future: () => T | PromiseLike<T>): Promise<T> {
    this.guard();
    return await Promise.resolve(future());
  }

  protected guard(): void {
    if (this.isUnmounted || !this.isInitialized) {
      throw mkErr({
        kind: InternalErrorKind.Abort,
        loc: "FutureComponent::guard",
        msg: "Promise canceled because unmounted",
      });
    }
  }

  private fuseFuture(future: Future): FusedFuture {
    const finished = { done: false, except: false };
    const launch = () => {
      void Promise.resolve(future.task())
        .catch((err) => {
          WARN("Exception on FutureComponent", err);
          finished.except = true;
        })
        .finally(() => {
          finished.done = true;
          this.schedule();
        });
    };

    const isDone = () => {
      return finished.done && !finished.except && future.isDone?.() !== false;
    };
    const isError = () => {
      return finished.done && (finished.except || future.isError?.() === true);
    };
    return {
      launch,
      isDone,
      isError,
      next: future.next,
      except: future.except,
    };
  }

  protected scheduleFuture(future: Future): void {
    if (this.isUnmounted || !this.isInitialized) {
      return;
    }
    const fused = this.fuseFuture(future);
    if (this.tasks.push(fused) === 1) {
      fused.launch();
    }
    this.schedule();
  }

  protected scheduleTask(task: () => void | PromiseLike<void>): void {
    this.scheduleFuture({ task });
  }
}
