import React, { useState } from "react";
import { Box, ButtonBase, Collapse } from "@material-ui/core";
import GoonoTypography from "@ui/goono/components/typo/GoonoTypography";
import { useServiceSidebarStyles } from "./styles/index";
import clsx from "clsx";
import { service, system, theme } from "@theme";
import ExpandLessRoundedIcon from "@material-ui/icons/ExpandLessRounded";
import { useGetOrganizationWorkspaceListQuery } from "@react-query/organization";
import { SkeletonBox } from "src/components/skeleton/SkeletonBox";
import { useHistory } from "react-router-dom";
import { ScreenURL } from "src/routes/route_list";
import { useQueryString } from "@utils/hooks/pure/useQueryString";
import Flex from "@ui/goono/components/layouts/Flex";

export default function WorkspaceList() {
  const classes = useServiceSidebarStyles();
  const workspaceListQuery = useGetOrganizationWorkspaceListQuery();
  const [open, setOpen] = useState<boolean>(true);
  const workspaceList = workspaceListQuery.data ?? [];
  const history = useHistory();
  const queryString = useQueryString();
  const selectedWorkspaceId = queryString.parse("id");

  const dotColorList = [
    system.warning_600,
    "#EB13D5",
    "#18C299",
    service.primary_400,
  ];

  const WorkspaceListTitleButton = () => {
    return (
      <ButtonBase
        className="title-button"
        onClick={() => setOpen((open) => !open)}
      >
        <GoonoTypography type="b2_b">워크스페이스 목록</GoonoTypography>
        <Box className="total-badge">{workspaceList.length}</Box>
        <ExpandLessRoundedIcon className={clsx("arrow-icon", open && "open")} />
      </ButtonBase>
    );
  };

  const WorkspaceListItem = (props: {
    dotColor: string;
    name: string;
    id: string;
  }) => {
    return (
      <ButtonBase
        className={classes.workspace_list_item}
        onClick={() =>
          history.push(`${ScreenURL.WORKSPACE_OVERVIEW}?id=${props.id}`)
        }
      >
        <Box className="dot" style={{ background: props.dotColor }}></Box>
        <GoonoTypography
          type="b2_m"
          noWrap
          style={selectedWorkspaceId === props.id ? { color: theme.white } : {}}
        >
          {props.name}
        </GoonoTypography>
      </ButtonBase>
    );
  };

  return (
    <Box className={classes.workspace_list_box}>
      <WorkspaceListTitleButton />
      <Box className="workspace-list">
        <Collapse in={open} disableStrictModeCompat>
          {workspaceListQuery.isLoading ? (
            <Flex container fullWidth flexDirection="column" gap="10px">
              {Array.from({ length: 5 }).map((_, index) => (
                <SkeletonBox
                  key={index}
                  style={{
                    backgroundColor: service.primary_900,
                    width: `100%`,
                    height: 20,
                  }}
                />
              ))}
            </Flex>
          ) : (
            workspaceList.map((workspace, index) => (
              <WorkspaceListItem
                key={workspace.id}
                id={workspace.id}
                name={workspace.name}
                dotColor={dotColorList[index % dotColorList.length]}
              />
            ))
          )}
        </Collapse>
      </Box>
    </Box>
  );
}
