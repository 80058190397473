import { createStyles, makeStyles } from "@material-ui/core";
import {
  gds_theme,
  gds_iconButtons,
  gds_buttons,
} from "@ui/goono/styles/theme";

const useStyles = makeStyles(() =>
  createStyles({
    btn_base: {
      ...gds_theme.btn_transition,
      ...gds_iconButtons.icon_btn_base,
      "&:disabled, &.Mui-disabled": {
        ...gds_iconButtons.btn_disabled,
      },
    },
    btn_rect: {
      borderRadius: gds_theme.round_3,
    },
    btn_xl: {
      ...gds_iconButtons.size_xl,
    },
    btn_lg: {
      ...gds_iconButtons.size_lg,
    },
    btn_md: {
      ...gds_iconButtons.size_md,
      "& .MuiIconButton-label, & .MuiSvgIcon-root, & svg": {
        width: 20,
        height: 20,
      },
    },
    btn_sm: {
      ...gds_iconButtons.size_sm,
      "& .MuiIconButton-label, & .MuiSvgIcon-root, & svg": {
        width: 14,
        height: 14,
      },
    },
    gray: {
      ...gds_iconButtons.gray,
      "@media (hover: hover)": {
        "&:hover": {
          ...gds_iconButtons.gray_hover,
        },
      },
      "&:active": {
        ...gds_iconButtons.gray_active,
      },
      "&:disabled, &.Mui-disabled": {
        background: gds_theme.white,
      },
    },
    gray_line: {
      ...gds_buttons.btn_gray_line,
      "@media (hover: hover)": {
        "&:hover": {
          ...gds_buttons.btn_gray_line_hover,
        },
      },
      "&:active": {
        ...gds_buttons.btn_gray_line_active,
      },
    },
    gray_fill: {
      ...gds_iconButtons.gray_fill,
      "@media (hover: hover)": {
        "&:hover": {
          ...gds_iconButtons.gray_fill_hover,
        },
      },
      "&:active": {
        ...gds_iconButtons.gray_fill_active,
      },
    },
    cool_line: {
      ...gds_buttons.btn_cool_line,
      "@media (hover: hover)": {
        "&:hover": {
          ...gds_buttons.btn_cool_line_hover,
        },
      },
      "&:active": {
        ...gds_buttons.btn_cool_line_active,
      },
    },
    primary: {
      ...gds_buttons.btn_primary,
      "@media (hover: hover)": {
        "&:hover": {
          ...gds_buttons.btn_primary_hover,
        },
      },
      "&:active": {
        ...gds_buttons.btn_primary_active,
      },
    },
    primary_line: {
      ...gds_buttons.btn_primary_line,
      "@media (hover: hover)": {
        "&:hover": {
          ...gds_buttons.btn_primary_line_hover,
        },
      },
      "&:active": {
        ...gds_buttons.btn_primary_line_active,
      },
    },
  })
);

export default useStyles;
