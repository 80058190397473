import React from "react";
import LandingBaseGrid from "./BaseGridByRouteType/Landing";
import PrivateBaseGrid from "./BaseGridByRouteType/Private";

export type BaseGridProps = {
  children?: React.ReactNode;
  viewerOption:
    | {
        type: "landing_page";
      }
    | {
        type: "auth_page";
      };
};

export default function BaseGrid(props: BaseGridProps) {
  const { viewerOption } = props;

  // 로그인 이전 화면 BaseGrid
  if (viewerOption.type === "landing_page") {
    return <LandingBaseGrid {...props} />;
  }

  // 로그인 후 화면 BaseGrid
  return <PrivateBaseGrid {...props} />;
}
