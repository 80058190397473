import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { gds_theme, gds_buttons } from "@ui/goono/styles/theme";

const useStyles = makeStyles((MuiTheme: Theme) =>
  createStyles({
    btn_base: {
      whiteSpace: "nowrap",
      borderRadius: gds_theme.round_3,
      ...gds_theme.btn_transition,
      ...gds_buttons.btn_base,
      "&:disabled, &.Mui-disabled": {
        ...gds_buttons.btn_disabled,
      },
    },
    btn_xl: {
      ...gds_buttons.size_xl,
      "&:has($icon_start)": {
        paddingRight: gds_theme.btn_padding_xl + 2,
      },
      "&:has($icon_end)": {
        paddingLeft: gds_theme.btn_padding_xl + 2,
      },
    },
    btn_lg: {
      ...gds_buttons.size_lg,
      "&:has($icon_start)": {
        paddingRight: gds_theme.btn_padding_lg + 2,
      },
      "&:has($icon_end)": {
        paddingLeft: gds_theme.btn_padding_lg + 2,
      },
    },
    btn_md: {
      ...gds_buttons.size_md,
      "&:has($icon_start)": {
        paddingRight: gds_theme.btn_padding_md + 2,
      },
      "&:has($icon_end)": {
        paddingLeft: gds_theme.btn_padding_md + 2,
      },
    },
    btn_sm: {
      ...gds_buttons.size_sm,
      "&:has($icon_start)": {
        paddingRight: gds_theme.btn_padding_sm + 2,
      },
      "&:has($icon_end)": {
        paddingLeft: gds_theme.btn_padding_sm + 2,
      },
      "& $icon_base": {
        ...gds_theme.btn_icon_sm,
      },
    },
    noIcon_lg: {
      ...gds_theme.btn_padding_lg_noIcon,
    },
    noIcon_md: {
      ...gds_theme.btn_padding_md_noIcon,
    },
    noIcon_sm: {
      ...gds_theme.btn_padding_sm_noIcon,
    },
    text_base: {},
    icon_base: {
      ...gds_theme.btn_icon_lg,
      "& img, & svg": {
        maxWidth: "100%",
        maxHeight: "100%",
        fill: "currentColor",
      },
    },
    icon_start: {
      marginRight: 4,
    },
    icon_end: {
      marginLeft: 4,
    },

    simple_xl: {
      "& $text_base": { display: "none" },
      "& $icon_start,& $icon_end": { margin: 0 },
    },
    simple_lg: {
      [MuiTheme.breakpoints.down(gds_theme.break_lg)]: {
        "& $text_base": { display: "none" },
        "& $icon_start,& $icon_end": { margin: 0 },
      },
    },
    simple_md: {
      [MuiTheme.breakpoints.down(gds_theme.break_md)]: {
        "& $text_base": { display: "none" },
        "& $icon_start,& $icon_end": { margin: 0 },
      },
    },
    simple_sm: {
      [MuiTheme.breakpoints.down(gds_theme.break_sm)]: {
        "& $text_base": { display: "none" },
        "& $icon_start,& $icon_end": { margin: 0 },
      },
    },

    primary: {
      ...gds_buttons.btn_primary,
      "@media (hover: hover)": {
        "&:hover": {
          ...gds_buttons.btn_primary_hover,
        },
      },

      "&:active": {
        ...gds_buttons.btn_primary_active,
      },
    },
    primary_line: {
      ...gds_buttons.btn_primary_line,
      "@media (hover: hover)": {
        "&:hover": {
          ...gds_buttons.btn_primary_line_hover,
        },
      },

      "&:active": {
        ...gds_buttons.btn_primary_line_active,
      },
    },
    white: {
      ...gds_buttons.btn_white,
      "@media (hover: hover)": {
        "&:hover": {
          ...gds_buttons.btn_white_hover,
        },
      },
      "&:active": {
        ...gds_buttons.btn_white_active,
      },
    },

    gray: {
      ...gds_buttons.btn_gray,
      "@media (hover: hover)": {
        "&:hover": {
          ...gds_buttons.btn_gray_hover,
        },
      },
      "&:active": {
        ...gds_buttons.btn_gray_active,
      },
    },

    gray_line: {
      ...gds_buttons.btn_gray_line,
      "@media (hover: hover)": {
        "&:hover": {
          ...gds_buttons.btn_gray_line_hover,
        },
      },
      "&:active": {
        ...gds_buttons.btn_gray_line_active,
      },
    },
    cool_gray: {
      ...gds_buttons.btn_cool,
      "@media (hover: hover)": {
        "&:hover": {
          ...gds_buttons.btn_cool_hover,
        },
      },
      "&:active": {
        ...gds_buttons.btn_cool_active,
      },
    },
    cool_gray_line: {
      ...gds_buttons.btn_cool_line,
      "@media (hover: hover)": {
        "&:hover": {
          ...gds_buttons.btn_cool_line_hover,
        },
      },
      "&:active": {
        ...gds_buttons.btn_cool_line_active,
      },
    },

    green: {
      ...gds_buttons.btn_green,
      "@media (hover: hover)": {
        "&:hover": {
          ...gds_buttons.btn_green_hover,
        },
      },
      "&:active": {
        ...gds_buttons.btn_green_active,
      },
    },

    danger: {
      ...gds_buttons.btn_danger,
      "@media (hover: hover)": {
        "&:hover": {
          ...gds_buttons.btn_danger_hover,
        },
      },
      "&:active": {
        ...gds_buttons.btn_danger_active,
      },
    },
    navy: {
      ...gds_buttons.btn_navy,
      "@media (hover: hover)": {
        "&:hover": {
          ...gds_buttons.btn_navy_hover,
        },
      },
      "&:active": {
        ...gds_buttons.btn_navy_active,
      },
    },
    success: {
      ...gds_buttons.btn_success,
      "@media (hover: hover)": {
        "&:hover": {
          ...gds_buttons.btn_success_hover,
        },
      },
      "&:active": {
        ...gds_buttons.btn_success_active,
      },
    },
    error: {
      ...gds_buttons.btn_error,
      "@media (hover: hover)": {
        "&:hover": {
          ...gds_buttons.btn_error_hover,
        },
      },
      "&:active": {
        ...gds_buttons.btn_error_active,
      },
    },
  })
);

export default useStyles;
