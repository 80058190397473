import React from "react";
import { Route, RouteProps, useHistory } from "react-router-dom";
import { ScreenURL } from "src/routes/route_list";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store/reducers";
import BaseGrid from "@utils/templates/BaseGrid";
import { UserStateStatus } from "src/redux/store/reducers/user";
import { useGetOrganizationQuery } from "@react-query/organization";
import CircularLoadingView from "src/components/view/CircularLoadingView";
import GoonoTypography from "@ui/goono/components/typo/GoonoTypography";
import SolidButton from "@ui/goono/components/buttons/SolidButton";
import { useLogoutMutation } from "@react-query/auth";

export const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const history = useHistory();
  const userState = useSelector((state: RootState) => state.user).state;
  const organizationQuery = useGetOrganizationQuery();
  const logoutMutation = useLogoutMutation();

  React.useEffect(() => {
    if (userState.status !== UserStateStatus.SUCCESS)
      history.push(ScreenURL.SIGN_IN);
  }, []);

  if (organizationQuery.isLoading)
    return (
      <div style={{ width: `100vw`, height: `100vh` }}>
        <CircularLoadingView />;
      </div>
    );

  if (organizationQuery.isError)
    return (
      <div
        style={{
          width: `100vw`,
          height: `100vh`,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <GoonoTypography style={{ marginBottom: `20px` }} type="h4">
          본인이 속한 조직이 없습니다.
        </GoonoTypography>
        <SolidButton
          onClick={() => logoutMutation.mutate()}
          buttonType="cool_gray"
        >
          로그아웃
        </SolidButton>
      </div>
    );

  return (
    <React.Suspense
      fallback={<BaseGrid viewerOption={{ type: "auth_page" }} />}
    >
      <Route {...rest} />
    </React.Suspense>
  );
};

export const LandingRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const history = useHistory();
  const userState = useSelector((state: RootState) => state.user).state;

  React.useEffect(() => {
    if (userState.status === UserStateStatus.SUCCESS)
      history.push(ScreenURL.WORKSPACE_LIST);
  }, []);

  return (
    <React.Suspense
      fallback={<BaseGrid viewerOption={{ type: "landing_page" }} />}
    >
      <Route {...rest} />
    </React.Suspense>
  );
};

export const CommonRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  return (
    <React.Suspense fallback={<></>}>
      <Route {...rest} />
    </React.Suspense>
  );
};
