import React from "react";
import { Box } from "@material-ui/core";
import { useServiceSidebarStyles } from "./styles/index";

import { ServiceSidebarHooks } from "./hooks";
import SidebarMenuItem from "./SidebarMenuItem";
import SidebarWorkspaceList from "./WorkspaceList";
import SidebarFooter from "./Footer";

export default function ServiceSidebar() {
  const classes = useServiceSidebarStyles();
  const commonMenuList = ServiceSidebarHooks.useCommonMenuList();

  const SidebarCommonMenuList = () => {
    return (
      <Box className={classes.gnb_container}>
        {commonMenuList.map((menu) => (
          <SidebarMenuItem {...menu} key={menu.title} />
        ))}
      </Box>
    );
  };

  return (
    <Box className={classes.service_sidebar_root}>
      <SidebarCommonMenuList />
      <SidebarWorkspaceList />
      <SidebarFooter />
    </Box>
  );
}
