import { useSelector } from "react-redux";
import { RootState } from "src/redux/store/reducers";
import { UserStateStatus } from "src/redux/store/reducers/user";

export const useLoginUserInfo = () => {
  const userState = useSelector((state: RootState) => state.user).state;

  if (userState.status !== UserStateStatus.SUCCESS)
    throw Error(`user state status err`);

  return userState.userInfo;
};
