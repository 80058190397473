import { makeStyles } from "@material-ui/core";
import landingBgImg from "@assets/images/background/landing_bg.png";

export const BaseSideBarWidth = 300;
export const BaseHeaderHeight = 70;
export const BaseContainerWidth = 1280;
export const ServiceHeaderZIndex = 10;
export const ServiceSidebarZIndex = 20;

export const useBaseGridStyles = makeStyles(() => ({
  landing_page_container: {
    width: "100%",
    minHeight: "100vh",
    background: `url(${landingBgImg}) no-repeat center / cover`,
  },

  auth_page_container: {
    marginTop: BaseHeaderHeight,
    marginLeft: BaseSideBarWidth,
    width: "100%",
    minHeight: `calc(100vh - ${BaseHeaderHeight}px)`,
    maxWidth: `calc(100% - ${BaseSideBarWidth}px)`,
  },
  auth_page_content_container: {
    maxWidth: 1268 + 40,
  },
}));
