export const delay = async (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const includesString = (o: object, key: string): boolean => {
  for (const field of Object.keys(o)) {
    const val = (o as Record<string, unknown>)[field];
    if (typeof val === "string") {
      if (val.includes(key)) {
        return true;
      }
    }
  }
  return false;
};

/**
 * Undefined 로 정의된 key 를 객체에서 제거한다.
 * @param o
 * @returns
 */
export function removeUndefined<T>(o: T): T {
  const object = o as unknown as { [index: string]: unknown };
  Object.keys(object).forEach((key) =>
    object[key] === undefined ? delete object[key] : {}
  );
  return object as unknown as T;
}

/**
 * Undefined 일 수도 있는 값의 타입을 변환한다.
 *
 * @param val
 * @param f
 * @returns
 */
export function mapUndefined<A, B>(val: A | undefined, f: (val: A) => B) {
  if (val === undefined) {
    return undefined;
  } else {
    return f(val);
  }
}

let _mkSingleton_counter = 0;
const _mkSingleton_dict = new Map<number, boolean>();

/**
 * Async task 가 동시에 두번 실행되지 않도록 한다.
 * 만일 이전 작업이 실행중이라면 두 번째 실행 요청은 무시한다.
 *
 * ```
 * const task = async () => {...};
 * const runner = mkSingletonTask(task);
 * runner();
 * runner();
 * ```
 *
 * @param task Async task
 * @returns 중복되지 않게 task 를 실행하는 런처
 */
export function mkSingletonTask<P = undefined>(
  task: (param: P) => void | PromiseLike<void>
): (param: P) => void {
  const current_index = _mkSingleton_counter++;
  _mkSingleton_dict.set(current_index, false);

  const runner = (param: P) => {
    if (_mkSingleton_dict.get(current_index) !== false) {
      // 이미 작업 실행 중
      return;
    }
    _mkSingleton_dict.set(current_index, true);
    void Promise.resolve(task(param)).finally(() => {
      _mkSingleton_dict.set(current_index, false);
    });
  };
  return runner;
}
