import {
  Enum,
  State,
  Action,
  ReduxStateType,
  StateMachine3,
} from "../reducers/state3";
import { FutureComponent } from "./FutureComponent";
import { getLoggers } from "@redwit-commons/utils/log";
import { Dispatch } from "redux";
import {
  HasErrorKind,
  InternalError,
  InternalErrorKind,
  isErr,
  mkErr,
} from "@redwit-commons/utils/exception2";
import { ReactNode } from "react";

const { WARN } = getLoggers("ReduxComponent3");

export type ReduxComponentProps<
  SE extends Enum,
  S extends State<SE>,
  AE extends Enum,
  A extends Action<AE>,
  Err extends HasErrorKind
> = {
  reduxState: ReduxStateType<SE, S, AE, A, Err>;
  dispatch: Dispatch;
  stateMachine: StateMachine3<SE, S, AE, A, Err>;
};

type InferredPropsType<P> = P extends ReduxComponentProps<
  infer _SE,
  infer _S,
  infer _AE,
  infer _A,
  infer _Err
>
  ? P
  : never;
type InferredStateType<P> = P extends ReduxComponentProps<
  infer _SE,
  infer S,
  infer _AE,
  infer _A,
  infer _Err
>
  ? S
  : never;
type InferredActionType<P> = P extends ReduxComponentProps<
  infer _SE,
  infer _S,
  infer _AE,
  infer A,
  infer _Err
>
  ? A
  : never;
type InferredErrorType<P> = P extends ReduxComponentProps<
  infer _SE,
  infer _S,
  infer _AE,
  infer _A,
  infer Err
>
  ? Err
  : never;

export abstract class ReduxStateComponent3<
  P,
  S = Record<string, never>
> extends FutureComponent<InferredPropsType<P>, S> {
  protected constructor(props: InferredPropsType<P>) {
    super(props);
  }

  // 정상적으로 종료 시 리턴
  private _doFinishAction(toState: InferredStateType<P>) {
    if (this.props.reduxState.action === undefined) {
      throw mkErr({
        kind: InternalErrorKind.Fatal,
        loc: "_doFinishAction",
        msg: "cannot finish without action",
        toState,
      });
    }

    const action = this.props.reduxState.action;
    this.scheduleFuture({
      task: () => {
        this.props.dispatch(this.props.stateMachine.newFinishAction(toState));
      },
      isDone: () => {
        return (
          this.props.reduxState.state.status === toState.status &&
          this.props.reduxState.action === undefined
        );
      },
      next: () => {
        return {
          task: action.onResolve,
        };
      },
    });
  }

  private _doCancelAction(err: InferredErrorType<P> | InternalError) {
    if (this.props.reduxState.action === undefined) {
      throw mkErr({
        kind: InternalErrorKind.Fatal,
        loc: "_doCancelAction",
        msg: "cannot cancel without action",
        err,
      });
    }
    const prevState = this.props.reduxState.state;
    const action = this.props.reduxState.action;
    this.scheduleFuture({
      task: () => {
        this.props.dispatch(this.props.stateMachine.newCancelAction());
      },
      isDone: () => {
        return (
          this.props.reduxState.state.status === prevState.status &&
          this.props.reduxState.action === undefined
        );
      },
      next: () => {
        return {
          task: () => action.onReject(err),
        };
      },
    });
  }

  private _doResetAction(err: InferredErrorType<P> | InternalError) {
    const action = this.props.reduxState.action;
    const initStatus = this.props.stateMachine.getInitState().status;
    this.resetScheduler();
    this.scheduleFuture({
      task: () => {
        this.props.dispatch(this.props.stateMachine.newResetAction());
      },
      isDone: () => {
        return (
          this.props.reduxState.state.status === initStatus &&
          this.props.reduxState.action === undefined
        );
      },
      next: () => {
        return {
          task: () => action?.onReject(err),
        };
      },
    });
  }

  // 타입 검사 쉽게 하기 위해서 만듬.
  protected mkStateErr(err: InferredErrorType<P>): InferredErrorType<P> {
    return this.props.stateMachine.mkStateError(
      err
    ) as unknown as InferredErrorType<P>;
  }

  componentDidMount(): void {
    super.componentDidMount();
    const currState = this.props.reduxState;
    const action = this.props.reduxState.action;
    const state = currState.state;
    if (action !== undefined) {
      WARN("이전에 처리되지 않은 액션 있음. 재실행.");
      this.scheduleTask(async () =>
        Promise.resolve(
          this.onAction(
            state as InferredStateType<P>,
            action.tryAction as InferredActionType<P>
          )
        )
          .then((toState) => {
            this._doFinishAction(toState);
          })
          .catch((exception: unknown) => {
            if (this.props.stateMachine.isStateError(exception)) {
              this._doCancelAction(
                mkErr({
                  kind: InternalErrorKind.Abort,
                  loc: "ReduxStateComponent3::componentDidMount",
                  msg: "Cancel previous action",
                  action,
                  state,
                })
              );
            } else if (isErr(exception)) {
              if (
                exception.kind === InternalErrorKind.Fatal ||
                exception.kind === InternalErrorKind.UncaughtException
              )
                this._doResetAction(exception);
              else this._doCancelAction(exception);
            } else {
              this._doResetAction(
                mkErr({ kind: InternalErrorKind.UncaughtException, exception })
              );
            }
          })
          .catch((exception) =>
            this._doResetAction(
              mkErr({
                kind: InternalErrorKind.Fatal,
                loc: "ReduxStateComponent3::componentDidMount",
                msg: "Exception while canceling action",
                exception,
              })
            )
          )
      );
    }
  }

  componentWillUnmount(): void {
    super.componentWillUnmount();
    const currState = this.props.reduxState;
    const action = this.props.reduxState.action;
    const state = currState.state;
    if (action !== undefined) {
      WARN("Unmount 이전 처리되지 않은 액션 있음. 버리기.", action, state);
      this.scheduleTask(() =>
        this._doCancelAction(
          mkErr({
            kind: InternalErrorKind.Abort,
            loc: "ReduxStateComponent3::componentWillUnmount",
            msg: "Cancel next action",
            action,
            state,
          })
        )
      );
    }
  }

  /**
   * onAction 처리 후 state 이동을 하려면 이동하려는 state 를 리턴하세요 (toState).
   * 작업을 취소하려면 Err 를 던지세요.
   */
  protected abstract onAction(
    state: Readonly<InferredStateType<P>>,
    action: Readonly<InferredActionType<P>>
  ): InferredStateType<P> | Promise<InferredStateType<P>>;

  componentDidUpdate(prevProps: Readonly<InferredPropsType<P>>): void {
    super.componentDidUpdate();
    /**
     * reduxState가 아닌 action에 대해 componentDidUpdate가 불리지 않아야 합니다
     */
    const currState = this.props.reduxState;
    const prevState = prevProps.reduxState;
    const action = currState.action;
    const state = currState.state;
    if (prevState.action === undefined && action !== undefined) {
      this.scheduleTask(async () =>
        Promise.resolve(
          this.onAction(
            state as InferredStateType<P>,
            action.tryAction as InferredActionType<P>
          )
        )
          .then((toState) => {
            this._doFinishAction(toState);
          })
          .catch((exception: unknown) => {
            if (this.props.stateMachine.isStateError(exception)) {
              this._doCancelAction(exception as InferredErrorType<P>);
            } else if (isErr(exception)) {
              if (
                exception.kind === InternalErrorKind.Fatal ||
                exception.kind === InternalErrorKind.UncaughtException
              )
                this._doResetAction(exception);
              else this._doCancelAction(exception);
            } else {
              this._doResetAction(
                mkErr({ kind: InternalErrorKind.UncaughtException, exception })
              );
            }
          })
          .catch((exception) =>
            this._doResetAction(
              mkErr({
                kind: InternalErrorKind.Fatal,
                loc: "ReduxStateComponent3::componentDidUpdate",
                msg: "Exception while canceling action",
                exception,
              })
            )
          )
      );
    }
  }

  render(): ReactNode {
    return null;
  }
}
