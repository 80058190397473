import React from "react";
import RoutingContents from "./RoutingContents";
import GlobalComponentManager from "./GlobalComponentManager";
import { useUserProfileRefresher } from "./hooks/useUserProfileRefresher";

export default function GoonoRoutingManager() {
  useUserProfileRefresher();

  return (
    <React.Fragment>
      <GlobalComponentManager />
      <RoutingContents />
    </React.Fragment>
  );
}
