import React from "react";
import { SnackbarStateStatus } from "../../redux/store/reducers/snackbar";
import { useSelector } from "react-redux";
import { RootState } from "src/redux/store/reducers";
import loadable from "@loadable/component";

const SnackBar = loadable(() => import("../../components/modals/SnackBar"));

export default function GoonoSnackbarManager() {
  const snackbar = useSelector((state: RootState) => state.snackbar);
  if (snackbar.state.status !== SnackbarStateStatus.OPENED) return <></>;
  return <SnackBar {...snackbar.state} />;
}
