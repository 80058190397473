import { makeStyles, alpha } from "@material-ui/core";
import { theme, service, typo, system } from "@theme";
import {
  BaseHeaderHeight,
  BaseSideBarWidth,
  ServiceSidebarZIndex,
} from "@utils/templates/styles/BaseGrid";

export const useServiceSidebarStyles = makeStyles(() => ({
  service_sidebar_root: {
    paddingBottom: 30,
    width: BaseSideBarWidth,
    position: "fixed",
    top: BaseHeaderHeight,
    left: 0,
    bottom: 0,
    zIndex: ServiceSidebarZIndex,
    borderTopRightRadius: 30,
    background: service.primary_1000,
    color: theme.white,
  },

  gnb_container: {
    padding: "30px 28px",
    borderBottom: `1px solid ${alpha(theme.white, 0.2)}`,
    display: "flex",
    flexDirection: "column",
    gap: 8,
  },
  sidebar_menu_item: {
    height: 48,
    paddingLeft: 16,
    borderRadius: theme.round_3,
    justifyContent: "flex-start",
    gap: 8,
    ...typo.b2_b,

    "&.selected": {
      background: alpha(theme.white, 0.1),
    },
  },

  workspace_list_box: {
    display: "flex",
    flexDirection: "column",
    padding: "30px 28px 0 44px",
    overflow: "hidden",
    height: "100%",
    maxHeight: "calc(100% - 232px)",

    "& .title-button": {
      width: "100%",
      justifyContent: "flex-start",
    },
    "& .total-badge": {
      marginLeft: 6,
      marginRight: "auto",
      padding: "0 12px",
      height: 20,
      borderRadius: 18,
      background: theme.primary,
      ...typo.caption1_b,
    },
    "& .arrow-icon": {
      transition: "all .25s",

      "&.open": {
        transform: "rotate(180deg)",
      },
    },
    "& .workspace-list": {
      marginTop: 24,
      overflow: "auto",
      display: "flex",
      flexDirection: "column",
      height: "100%",
      maxHeight: "100%",

      "&::-webkit-scrollbar": {
        width: 4,
      },
      "&::-webkit-scrollbar-thumb": {
        background: theme.gray_5_body,
        borderRadius: theme.round_4,
      },
    },
  },
  workspace_list_item: {
    marginBottom: 16,
    maxWidth: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: 8,
    color: alpha(theme.white, 0.7),
    overflow: "hidden",

    "& .dot": {
      width: 6,
      height: 6,
      borderRadius: "50%",
      background: system.warning_600,
      flexShrink: 0,
    },
  },
  bottom_container: {
    width: "100%",
    padding: "0 28px",
    position: "absolute",
    bottom: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    "& .version": {
      color: theme.gray_3,
    },
  },
}));
