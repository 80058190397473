import React from "react";
import { ButtonBase } from "@material-ui/core";
import GoonoTypography from "@ui/goono/components/typo/GoonoTypography";
import { ReactComponent as LogoImg } from "@assets/images/logo/logo.svg";
import { service } from "@theme";

type HeaderLogoProps = {
  onClick: () => void;
};
export default function CommonHeaderLogo({ onClick }: HeaderLogoProps) {
  return (
    <h1>
      <ButtonBase className="logo-btn" onClick={onClick}>
        <LogoImg />
        <GoonoTypography
          type="h4"
          component="span"
          children="조직관리자"
          style={{ color: service.primary_800 }}
        />
      </ButtonBase>
    </h1>
  );
}
