import T from "@redwit-commons/utils/typecheck";

export type OrganizationAdmin = {
  id: string;
  email: string;
  name: string;
};

export type Organization = {
  id: string;
  name: string;
  memberCount: number;
};

export const OrganizationAdminSchema = T.object()
  .addField("id", T.string())
  .addField("email", T.string())
  .addField("name", T.string());

export const OrganizationSchema = T.object()
  .addField("id", T.string())
  .addField("name", T.string())
  .addField("memberCount", T.number());
