import { Dispatch } from "redux";
import { InternalError } from "@redwit-commons/utils/exception2";
import {
  StateMachine3,
  transition,
  mkReducer,
  StateMachineAction,
} from "@redwit-react-commons/reducers/state3";

export enum ModalStateStatus {
  INIT = "Modal::INIT",
}

export enum ModalActionKind {
  TRY_OPEN = "ModalAction::TRY_OPEN",
  TRY_CLOSE = "ModalAction::TRY_CLOSE",
  TRY_CLOSE_ALL = "ModalAction::TRY_CLOSE_ALL",
}

export enum ModalType {
  PASSWORD_MODIFY = "ModalType::PASSWORD_MODIFY",
}

export type ModalError = never;

export type PasswordModifyModalProps = {};

type GenericModalInfo<T = ModalType, U = any> = {
  readonly type: T;
} & U;

export type ModalInfo = GenericModalInfo<
  ModalType.PASSWORD_MODIFY,
  PasswordModifyModalProps
>;

export type ModalState = {
  readonly status: ModalStateStatus.INIT;
  readonly openedModalList: ModalInfo[];
};

export type ModalAction =
  | ({
      readonly kind: ModalActionKind.TRY_OPEN;
    } & ModalInfo)
  | {
      readonly kind: ModalActionKind.TRY_CLOSE;
    }
  | {
      readonly kind: ModalActionKind.TRY_CLOSE_ALL;
    };

const smid = "MODAL_STATE_MACHINE3";

export type ModalStateMachineType = StateMachine3<
  ModalStateStatus,
  ModalState,
  ModalActionKind,
  ModalAction,
  ModalError
>;

export const modalStateMachine: ModalStateMachineType = new StateMachine3<
  ModalStateStatus,
  ModalState,
  ModalActionKind,
  ModalAction,
  ModalError
>(smid, { status: ModalStateStatus.INIT, openedModalList: [] }, [
  transition(
    ModalStateStatus.INIT,
    ModalStateStatus.INIT,
    ModalActionKind.TRY_OPEN
  ),
  transition(
    ModalStateStatus.INIT,
    ModalStateStatus.INIT,
    ModalActionKind.TRY_CLOSE
  ),
  transition(
    ModalStateStatus.INIT,
    ModalStateStatus.INIT,
    ModalActionKind.TRY_CLOSE_ALL
  ),
]);

export type DispatchModalAction = Dispatch<
  StateMachineAction<
    ModalStateStatus,
    ModalState,
    ModalActionKind,
    ModalAction,
    ModalError
  >
>;
export default mkReducer<
  ModalStateStatus,
  ModalState,
  ModalActionKind,
  ModalAction,
  ModalError
>(modalStateMachine);
export const doModalAction = (
  dispatch: DispatchModalAction,
  nextAction: ModalAction,
  onResolve: () => void = () => {},
  onReject: (err: ModalError | InternalError) => void = () => {}
) => {
  dispatch(modalStateMachine.newTryAction(nextAction, onResolve, onReject));
};
export const doModalActionAsync = (
  dispatch: DispatchModalAction,
  nextAction: ModalAction
) => {
  return new Promise<void>((resolve, reject) => {
    dispatch(modalStateMachine.newTryAction(nextAction, resolve, reject));
  });
};
export const resetModal = (dispatch: DispatchModalAction) => {
  dispatch(modalStateMachine.newResetAction());
};
