import React from "react";

import { ButtonBase } from "@material-ui/core";
import { useServiceSidebarStyles } from "./styles/index";

import clsx from "clsx";

export type SidebarMenuItemProps = {
  icon: React.ReactNode;
  title: string;
  isSelected: boolean;
  onClickMenu: () => void;
};

export default function SidebarMenuItem(props: SidebarMenuItemProps) {
  const classes = useServiceSidebarStyles();
  return (
    <ButtonBase
      className={clsx(
        classes.sidebar_menu_item,
        props.isSelected && "selected"
      )}
      onClick={props.onClickMenu}
    >
      {props.icon}
      {props.title}
    </ButtonBase>
  );
}
