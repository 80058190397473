import React from "react";
import { ButtonBase, FormLabel } from "@material-ui/core";
import useStyles from "./styles/SolidButton";
import Flex from "../layouts/Flex";
import clsx from "clsx";

export type ButtonType =
  | "primary"
  | "primary_line"
  | "gray"
  | "white"
  | "gray_line"
  | "cool_gray"
  | "cool_gray_line"
  | "green"
  | "danger"
  | "success"
  | "error"
  | "navy";
type ButtonSize = "xl" | "lg" | "md" | "sm";
export type SolidButtonProps = {
  customId?: string;
  /** 버튼 컬러 타입 */
  buttonType?: ButtonType;
  /**
   * 버튼 크기 - height로 판단 [48,40,32,28] 순서
   * */
  buttonSize?: ButtonSize;
  /** width: 100% 설정 */
  fullWidth?: boolean;
  /*
   * icon + text에서 text가 사라지는 breakPoint 설정
   */
  simpleMode?: ButtonSize;
  /**비활성화 */
  disabled?: boolean;
  className?: string;
  dataTestid?: string;
  onClick?: (evt: React.MouseEvent<HTMLButtonElement>) => void;
  /**form label for 설정 */
  htmlFor?: string;
  /** 내부 아이콘의 Class */
  iconClass?: string;
  /** 좌측 아이콘, svg 추천 */
  startIcon?: React.ReactNode;
  /** 우측 아이콘, svg 추천 */
  endIcon?: React.ReactNode;
  /** 버튼 안의 내용 - 텍스트 */
  children?: React.ReactNode;
  submit?: boolean;
};

/**
 * - Background & Border 가 있는 버튼
 */
const SolidButton: React.FC<SolidButtonProps> = (props: SolidButtonProps) => {
  const {
    customId,
    buttonType,
    buttonSize,
    fullWidth,
    simpleMode,
    disabled,
    className,
    dataTestid,
    onClick,
    htmlFor,
    iconClass,
    startIcon,
    endIcon,
    children,
    submit,
  } = props;

  const classes = useStyles();

  const getSizeClass = () => {
    switch (buttonSize) {
      case "xl":
        return [classes.btn_xl];
      case "lg":
        return [classes.btn_lg];
      case "md":
        return [classes.btn_md];
      case "sm":
        return [classes.btn_sm];
      default:
        return [classes.btn_lg];
    }
  };
  const getSizeNoIcon = () => {
    if (startIcon !== undefined || endIcon !== undefined) return [];
    switch (buttonSize) {
      case "xl":
        return [];
      case "lg":
        return [classes.noIcon_lg];
      case "md":
        return [classes.noIcon_md];
      case "sm":
        return [classes.noIcon_sm];
      default:
        return [classes.noIcon_lg];
    }
  };
  const getColorClass = () => {
    switch (buttonType) {
      case "primary":
        return [classes.primary];
      case "primary_line":
        return [classes.primary_line];
      case "gray":
        return [classes.gray];
      case "white":
        return [classes.white];
      case "gray_line":
        return [classes.gray_line];
      case "cool_gray":
        return [classes.cool_gray];
      case "cool_gray_line":
        return [classes.cool_gray_line];
      case "green":
        return [classes.green];
      case "danger":
        return [classes.danger];
      case "navy":
        return [classes.navy];
      case "success":
        return [classes.success];
      case "error":
        return [classes.error];
      default:
        return [classes.gray_line];
    }
  };

  const getSimpleModeClass = () => {
    switch (simpleMode) {
      case "xl":
        return [classes.simple_xl];
      case "lg":
        return [classes.simple_lg];
      case "md":
        return [classes.simple_md];
      case "sm":
        return [classes.simple_sm];
      default:
        return [];
    }
  };
  const component =
    htmlFor !== undefined && htmlFor && (disabled ?? true)
      ? { component: FormLabel, htmlFor }
      : {};

  return (
    <ButtonBase
      {...component}
      id={customId}
      data-testid={dataTestid}
      disabled={disabled}
      className={clsx(
        classes.btn_base,
        ...getColorClass(),
        ...getSizeClass(),
        ...getSizeNoIcon(),
        ...getSimpleModeClass(),
        className
      )}
      onClick={onClick}
      disableRipple
      style={{
        width: fullWidth != undefined && fullWidth ? "100%" : "auto",
      }}
      type={submit != undefined && submit ? "submit" : "button"}
    >
      {startIcon != undefined && startIcon && (
        <Flex
          container
          alignItems="center"
          justifyContent="center"
          id={customId}
          className={clsx(classes.icon_base, classes.icon_start, iconClass)}
        >
          {startIcon}
        </Flex>
      )}
      <span className={classes.text_base}>{children}</span>
      {endIcon != undefined && endIcon && (
        <Flex
          container
          alignItems="center"
          justifyContent="center"
          id={customId}
          className={clsx(classes.icon_base, classes.icon_end, iconClass)}
        >
          {endIcon}
        </Flex>
      )}
    </ButtonBase>
  );
};

SolidButton.defaultProps = {
  buttonType: "gray_line",
  buttonSize: "lg",
};

export default SolidButton;
