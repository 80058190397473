export const useQueryString = () => {
  const getParams = (): Record<string, string> => {
    const url = window.location.href;
    const params: Record<string, string> = {};
    const queryString = url.split("?")[1];

    if (queryString) {
      const pairs = queryString.split("&");

      pairs.forEach((pair) => {
        const [key, value] = pair.split("=");
        params[key] = decodeURIComponent(value);
      });
    }

    return params;
  };

  /** 파싱하려는 queryString의 key값을 넣으면 value를 반환 */
  const parse = (queryKey: string): string | undefined => {
    const params = getParams();
    return params[queryKey];
  };

  const update = (props: { key: string; value: any }) => {
    const params = getParams();
    params[props.key] = encodeURIComponent(props.value);

    const freshURL = `${location.pathname}?${Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join("&")}`;

    history.replaceState({}, "", freshURL);
  };

  return {
    parse,
    update,
  };
};
