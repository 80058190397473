import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../store/reducers";
import { ReduxStateComponent3 } from "@redwit-react-commons/template/ReduxStateComponent3";
import {
  UserAction,
  UserActionKind,
  UserState,
  userStateMachine,
  UserStateMachineType,
  UserStateStatus,
} from "../store/reducers/user";

const mapStateToProps = (state: RootState) => {
  return { reduxState: state.user };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type UserContainerProps = PropsFromRedux & {
  stateMachine: UserStateMachineType;
};

export const handleUserAction = (
  _prevState: UserState,
  action: UserAction
): UserState => {
  switch (action.kind) {
    case UserActionKind.CLEAR: {
      return {
        status: UserStateStatus.INIT,
      };
    }

    case UserActionKind.REGIST_USER_INFO: {
      return {
        status: UserStateStatus.SUCCESS,
        userInfo: action.userInfo,
      };
    }
  }
};

class UserContainer extends ReduxStateComponent3<UserContainerProps> {
  static defaultProps = { stateMachine: userStateMachine };

  constructor(props: UserContainerProps) {
    super(props);
  }

  protected onAction = handleUserAction;
}

export default connector(UserContainer);
