// @flow
import React from "react";
import ModalContainer from "./ModalContainer";
import UserContainer from "./UserContainer";
import SnackbarContainer from "./SnackbarContainer";

// 다양한 container 들을 포함하도록 감싸줌.
export default class ContainerProvider extends React.PureComponent {
  render() {
    return (
      <>
        <ModalContainer />
        <UserContainer />
        <SnackbarContainer />
        {this.props.children}
      </>
    );
  }
}
