import React, { useState, useRef } from "react";
import { FormControl, InputBase, ButtonBase } from "@material-ui/core";
import GoonoTypography from "../typo/GoonoTypography";
import useStyles from "./styles/GoonoTextField";
import Flex from "@ui/goono/components/layouts/Flex";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import clsx from "clsx";
import { debounce } from "lodash";

type inputSize = "lg" | "md" | "sm";

export type GoonoTextFieldProps = {
  offChangeHandlerSetValue?: boolean;
  /** input size를 설정합니다 */
  inputSize?: inputSize;
  id?: string;
  /**input 상단 label을 입력합니다 */
  label?: string;
  /**label 우측 필수요소 표시입니다 */
  labelDot?: boolean;
  placeholder?: string;
  /**helperText를 입력합니다 */
  helperText?: string;
  disabled?: boolean;
  /**error state를 표시합니다 */
  errorMode?: boolean;
  /**input value를 체크합니다. maxLength와 함께 사용해야 합니다 */
  counter?: boolean;
  /**input의 maxLength를 설정합니다. maxLength이상은 입력되지 않습니다 */
  maxLength?: number;
  dataTestid?: string;
  className?: string;
  inputClass?: string;
  /**좌측 아이콘 입니다 */
  startIcon?: React.ReactNode;
  /**우측 아이콘 입니다 */
  endIcon?: React.ReactNode;
  endButton?: React.ReactNode;
  type?: string;
  /** 값 입력시 value 초기화하는 우측 버튼입니다 */
  resetBtn?: boolean;
  /* value가 변경되면 input 값이 변경됩니다 */
  value?: string | number;
  /* debounceTimeout 동안 input 내용이 바뀌지 않을 시 onChange가 실행됩니다 */
  debounceTimeout?: number;
  autoFocus?: boolean;
  /** textarea component로 지정 */
  isTextArea?: boolean;
  autoComplete?: string;
  onChange?: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (evt: any) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onReset?: () => void;
};

type CounterProps = {
  currentLength?: number;
};
const CounterBox = (props: CounterProps & GoonoTextFieldProps) => {
  const { maxLength, currentLength } = props;
  const classes = useStyles();
  return (
    <Flex container justifyContent="flex-end" className={classes.counterBox}>
      <GoonoTypography
        className={clsx(classes.counter, classes.counter_current)}
        noWrap
      >
        {currentLength}
      </GoonoTypography>
      <GoonoTypography
        className={clsx(classes.counter, classes.counter_max)}
        noWrap
      >
        /{maxLength}
      </GoonoTypography>
    </Flex>
  );
};

const HelperText = (props: GoonoTextFieldProps) => {
  const { helperText, dataTestid } = props;
  const classes = useStyles();

  return (
    <GoonoTypography
      dataTestid={`${dataTestid}-helper-text`}
      className={classes.helpertext}
      guideText
    >
      {helperText}
    </GoonoTypography>
  );
};

const IconBox = (props: GoonoTextFieldProps) => {
  const { startIcon, endIcon } = props;
  const classes = useStyles();

  return (
    <Flex
      container
      alignItems="cenrter"
      justifyContent="center"
      className={clsx(
        classes.iconBase,
        startIcon && classes.startIcon,
        endIcon && classes.endIcon
      )}
    >
      {startIcon}
      {endIcon}
    </Flex>
  );
};

/**
 * - Input, TextField 공통 컴포넌트
 */
const GoonoTextField: React.FC<GoonoTextFieldProps> = (
  props: GoonoTextFieldProps
) => {
  const {
    inputSize,
    id,
    label,
    labelDot,
    placeholder,
    helperText,
    maxLength,
    counter,
    disabled,
    errorMode,
    dataTestid,
    className,
    inputClass,
    startIcon,
    endIcon,
    type,
    resetBtn,
    endButton,
    debounceTimeout,
    value,
    autoFocus,
    isTextArea,
    onChange,
    onFocus,
    onBlur,
    onReset,
    onKeyDown,
  } = props;
  const [inputVal, setInputVal] = useState(String(value ?? ""));
  const inputRef = useRef<HTMLInputElement>(null);
  const classes = useStyles();

  React.useEffect(() => {
    setInputVal(String(value ?? ""));
  }, [value]);

  const isMaxLengthExceeded = (inputValue: string) => {
    return (
      maxLength !== undefined &&
      inputValue.length > inputVal.length &&
      inputValue.length > maxLength + 1
    );
  };

  const guardWithMaxLength = (inputValue: string) => {
    return inputValue.slice(0, (maxLength as number) + 1);
  };

  const changeHandlerAction = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = evt.target.value;

    if (isMaxLengthExceeded(inputValue)) {
      const val = guardWithMaxLength(inputValue);
      const { target, ...remain } = evt;

      onChange && onChange({ target: { ...target, value: val }, ...remain });
    } else onChange && onChange(evt);
  };

  const changeHandler = (evt: React.ChangeEvent<HTMLInputElement>) => {
    evt.persist();
    const inputValue = evt.target.value;

    if (isMaxLengthExceeded(inputValue))
      setInputVal(guardWithMaxLength(inputValue));
    else if (props.offChangeHandlerSetValue !== true) setInputVal(inputValue);

    if (debounceTimeout !== undefined) debounceOnChange(evt);
    else changeHandlerAction(evt);
  };

  const debounceOnChange = React.useCallback(
    debounce((evt: React.ChangeEvent<HTMLInputElement>) => {
      changeHandlerAction(evt);
    }, debounceTimeout as number),
    []
  );

  const resetHandler = () => {
    setInputVal("");
    inputRef.current?.focus();
    onReset && onReset();
  };

  const getSizeClass = () => {
    switch (inputSize) {
      case "lg":
        return [classes.size_lg];
      case "md":
        return [classes.size_md];
      case "sm":
        return [classes.size_sm];
      default:
        return [classes.size_md];
    }
  };

  const inputProps = {
    maxLength: maxLength && maxLength + 1,
    autoComplete: props.autoComplete ? props.autoComplete : "off",
  };

  const onResetBtn = () => {
    return (
      <ButtonBase
        disableRipple
        onClick={resetHandler}
        className={classes.resetBtn}
      >
        <CancelRoundedIcon />
      </ButtonBase>
    );
  };

  const getEndAdorment = () => {
    if (endIcon) return <IconBox endIcon={endIcon} />;
    if (endButton) return endButton;
    if (resetBtn && inputVal.trim().length > 0) return onResetBtn();
    return false;
  };

  return (
    <FormControl
      data-testid={"text-field-test-id"}
      className={clsx(classes.root, errorMode && classes.errorMode, className)}
    >
      {label && (
        <label
          className={clsx(classes.label, labelDot && classes.labelDot)}
          htmlFor={`${id}`}
        >
          {label}
        </label>
      )}
      <InputBase
        startAdornment={startIcon && <IconBox startIcon={startIcon} />}
        endAdornment={getEndAdorment()}
        disabled={disabled}
        classes={{
          root: clsx(
            classes.inputBase,
            ...getSizeClass(),
            inputVal.length > 0 && classes.border,
            disabled && classes.disabled,
            inputClass
          ),
          input: classes.input,
        }}
        id={`${id}`}
        data-testid={dataTestid}
        onChange={(evt) =>
          changeHandler(evt as React.ChangeEvent<HTMLInputElement>)
        }
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        placeholder={placeholder && `${placeholder}`}
        type={type}
        value={inputVal}
        inputRef={inputRef}
        autoFocus={autoFocus}
        inputProps={{
          ...inputProps,
        }}
        multiline={isTextArea}
      />
      {helperText || counter ? (
        <Flex
          container
          alignItems="center"
          justifyContent="space-between"
          className={classes.bottomBox}
        >
          {helperText && (
            <HelperText dataTestid={dataTestid} helperText={helperText} />
          )}
          {counter && (
            <CounterBox currentLength={inputVal.length} maxLength={maxLength} />
          )}
        </Flex>
      ) : undefined}
    </FormControl>
  );
};

GoonoTextField.defaultProps = {
  inputSize: "md",
};

export default GoonoTextField;
