import { applyMiddleware, createStore, compose } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer, PersistConfig } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer, { RootState } from "./reducers";

export interface ReduxDevToolsWindow extends Window {
  __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
}

declare let window: ReduxDevToolsWindow;

/** persist redux 수정할 때 마다 반드시 버전 1씩 계속 증가시킬 것. */
export const VERSION = 1;

export const KEY_STRING = "GoonoWebService2.0ReduxStore" + VERSION;

const persistConfig: PersistConfig<RootState> = {
  key: KEY_STRING,
  storage: storage,
  /** persit로 저장할 값들 */
  whitelist: ["user"],
};

const composeEnhancers =
  (typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(thunk))
);

export const persistor = persistStore(store as any);
