import { makeStyles } from "@material-ui/core";
import { theme } from "@theme";
import {
  BaseHeaderHeight,
  BaseSideBarWidth,
  ServiceHeaderZIndex,
} from "@utils/templates/styles/BaseGrid";

export const useServiceHeaderStyles = makeStyles(() => ({
  service_header_root: {
    width: "100%",
    position: "fixed",
    top: 0,
    zIndex: ServiceHeaderZIndex,
  },
  service_header_content: {
    width: "100%",
    height: BaseHeaderHeight,
    alignItems: "center",
    background: theme.white,
    ...theme.shadow_2,

    "& .header-logo-box": {
      paddingLeft: 36,
      width: BaseSideBarWidth,
    },
    "& .header-right-box": {
      flex: 1,
      alignItems: "center",
      position: "relative",
    },
  },
  header_search_form: {
    "&&": {
      marginRight: 20,
      maxWidth: 700,
      border: 0,
    },
  },
  header_input: {
    "&&": {
      background: theme.gray_1,
      border: 0,
    },
  },

  header_right_btn_group: {
    position: "absolute",
    top: "50%",
    right: 20,
    transform: "translateY(-50%)",
  },
  header_organization_badge: {
    height: 38,
    padding: "0 16px",
    marginLeft: "auto",
    marginRight: 12,
    borderRadius: 20,
    color: theme.gray_8,
    background: theme.gray_3,
    whiteSpace: "nowrap",
    ...theme.flex_center,
  },
}));
