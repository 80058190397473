import {
  gds_service,
  gds_system,
  gds_ect,
  gds_theme,
  gds_typo,
  gds_buttons,
  gds_textButtons,
  gds_iconButtons,
} from "@ui/goono/styles/theme";

export const service = gds_service;
export const system = gds_system;
export const ect = gds_ect;
export const theme = gds_theme;
export const typo = gds_typo;
export const buttons = gds_buttons;
export const textButtons = gds_textButtons;
export const iconButtons = gds_iconButtons;
