import { combineReducers } from "redux";
import user from "./user";
import modal from "./modal";
import snackbar from "./snackbar";

const rootReducer = combineReducers({
  modal,
  user,
  snackbar,
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
