import React from "react";
import { useServiceHeaderStyles } from "./styles/ServiceHeader";
import { Box, ButtonBase, Container } from "@material-ui/core";
import Flex from "@ui/goono/components/layouts/Flex";
import GoonoTypography from "@ui/goono/components/typo/GoonoTypography";
import GoonoTextField from "@ui/goono/components/inputs/GoonoTextField";
import IconOnlyButton from "@ui/goono/components/buttons/IconOnlyButton";
import HeaderLogo from "./CommonHeaderLogo";
import { useHistory } from "react-router-dom";
import { ScreenURL } from "src/routes/route_list";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import TurnedInNotRoundedIcon from "@material-ui/icons/TurnedInNotRounded";
import ExitToAppRoundedIcon from "@material-ui/icons/ExitToAppRounded";
import { useLogoutMutation } from "@react-query/auth";
import { useOrganizationInfo } from "@utils/hooks/service/useOrganizationInfo";

export default function ServiceHeader() {
  const classes = useServiceHeaderStyles();
  const history = useHistory();
  const logoutMutation = useLogoutMutation();
  const organization = useOrganizationInfo();

  const [searchText, setSearchText] = React.useState(``);

  const search = () => {
    history.push(`${ScreenURL.SEARCH_RESULT}?searchText=${searchText}`);
    setSearchText(``);
  };

  const renderSearchIconButton = () => {
    return (
      <ButtonBase onClick={search}>
        <SearchRoundedIcon />
      </ButtonBase>
    );
  };

  const renderLogo = () => {
    return (
      <Box className="header-logo-box">
        <HeaderLogo onClick={() => history.push(ScreenURL.WORKSPACE_LIST)} />
      </Box>
    );
  };

  const renderHeaderSearchForm = () => {
    return (
      <GoonoTextField
        autoComplete="off"
        id="service-header-search"
        inputSize="sm"
        placeholder="워크스페이스명을 입력해주세요."
        className={classes.header_search_form}
        inputClass={classes.header_input}
        endButton={renderSearchIconButton()}
        value={searchText}
        onChange={(evt) => setSearchText(evt.target.value)}
        onKeyDown={(evt) => {
          if (evt.keyCode === 13) search();
        }}
      />
    );
  };

  const renderOrganizationName = () => {
    return (
      <Box className={classes.header_organization_badge}>
        <TurnedInNotRoundedIcon />
        <GoonoTypography type="b3_b">{organization.name}</GoonoTypography>
      </Box>
    );
  };

  const renderLogoutButton = () => {
    return (
      <IconOnlyButton
        buttonType="gray_line"
        buttonSize="md"
        radius="rect"
        onClick={() => logoutMutation.mutate()}
      >
        <ExitToAppRoundedIcon />
      </IconOnlyButton>
    );
  };

  return (
    <Box className={classes.service_header_root}>
      <Flex container className={classes.service_header_content}>
        {renderLogo()}
        <Flex container className="header-right-box">
          <Container>{renderHeaderSearchForm()}</Container>
          <Flex
            container
            alignItems="center"
            className={classes.header_right_btn_group}
          >
            {renderOrganizationName()}
            {renderLogoutButton()}
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
}
