import React from "react";
import { Box } from "@material-ui/core";
import { BaseGridProps } from "../BaseGrid";
import { useBaseGridStyles } from "../styles/BaseGrid";

import LandingHeader from "src/navs/LandingHeader";

export default function LandingBaseGrid(props: BaseGridProps) {
  const { viewerOption, children } = props;
  const classes = useBaseGridStyles();

  if (viewerOption.type !== "landing_page") return <></>;
  return (
    <React.Fragment>
      <LandingHeader />
      <Box className={classes.landing_page_container}>{children}</Box>
    </React.Fragment>
  );
}
