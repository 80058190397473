/**
 * 주어진 task 를 백그라운드로 실행.
 *
 * # TODO
 *
 * Web worker 혹은 NodeJS 의 worker thread 가 가능한 경우 사용
 *
 * @param task 백그라운드로 실행할 작업
 */
let bgTaskLauncher: ((task: () => unknown) => unknown) | undefined = undefined;

/**
 * BgTask 를 실행 할 런처를 바꾼다.
 * @param launcher
 */
export const setBgTaskLauncher = (launcher: (task: () => unknown) => void) => {
  bgTaskLauncher = launcher;
};

/**
 * BgTask 를 실행한다. 만약 런처가 세팅되어 있지 않다면 foreground 에서
 * 실행한다.
 * @param task
 */
export const launchBgTask = (task: () => unknown) => {
  bgTaskLauncher ? bgTaskLauncher(task) : task();
};

/**
 * BgTask scheduler 가 가질 수 있는 상태
 */
enum BgTaskState {
  /**
   * 한가한 상태
   */
  Idle,
  /**
   * 큐 길이를 검사하기 위해서 event queue 마지막에 task를 등록한 뒤 기다리고
   * 있는 상태
   */
  Checking,
  /**
   * 바쁜거 확인 후 스로틀링을 위해서 쉬고 있는 상태
   */
  Throttling,
}

/**
 * BgTask 스케줄러의 Context
 */
type BgTaskCtx = (
  | {
      state: BgTaskState.Idle;
    }
  | {
      state: BgTaskState.Checking;
      /**
       * Probing task 를 삽입한 시각
       */
      start_time: number;
    }
  | {
      state: BgTaskState.Throttling;
    }
) & {
  /**
   * Task queue
   */
  queue: Array<() => unknown>;
};

/**
 * 보편적으로 잘 동작하는 task launcher 를 생성한다.
 *
 * @param option.idle_thres setTimeout(0) 실행 시 주어진 시간(ms) 보다 빠르게
 * 리턴하면 idle 상태로 판단.
 * @param option.batch_thres 실행 기회가 주어졌을 때 주어진 시간(ms) 보다 빠르게
 * task 가 종료되면 다음 task 를 이어서 실행한다.
 * @param option.throttle_sleep Throttle 상태로 판단되었을 때 sleep 할 시간.
 * @param option.immediate_fn Javascript event queue 의 맨 마지막에 task를 넣을
 * 때 사용할 함수. 일반적으로 setTimeout(0) 을 쓰지만 setTimeout(1),
 * setImmediate, process.nextTick 등의 여러 선택지가 가능하다.
 * @returns
 */
export const mkIdleBgTaskLauncher = (
  option: {
    idle_thres?: number;
    batch_thres?: number;
    throttle_sleep?: number;
    immediate_fn?: (task: () => void) => void;
  } = {}
) => {
  const default_config = {
    idle_thres: 10,
    batch_thres: 10,
    throttle_sleep: 100,
    immediate_fn: (task: () => void) => {
      setTimeout(task, 0);
      // 또는 sleep 1ms 을 하거나
      // 또는 setImmediate 를 부르거나
      // 등의 선택지가 있음
    },
  };
  const config = {
    ...default_config,
    ...option,
  };
  const context: BgTaskCtx = {
    state: BgTaskState.Idle,
    queue: [],
  } as BgTaskCtx;
  const shared = {
    context,
  };
  // 타임아웃 발생 시 항상 이 함수가 불린다.
  // 현재 state를 검사하고 적절한 action 을 수행한 뒤,
  // 필요에 따라 스스로를 다시 timeout 목록에 넣는다.
  const checker = () => {
    switch (shared.context.state) {
      case BgTaskState.Checking: {
        const diff_ms = Date.now() - shared.context.start_time;
        if (diff_ms < config.idle_thres) {
          // 큐가 여유롭다는 간접 증거
          const loop_start = Date.now();
          for (;;) {
            const task = shared.context.queue.shift();
            if (task !== undefined) {
              // task 실행
              task();
            } else {
              // 더이상 task 없으므로 Idle
              shared.context = {
                ...shared.context,
                state: BgTaskState.Idle,
              };
              return;
            }
            if (Date.now() - loop_start > config.batch_thres) {
              // task 가 충분히 시간을 잡아먹었으면 다른 작업에 양보
              // 하단부 "큐가 여유롭지 않은 상태" 로 이동한다.
              break;
            }
            // 만약 task 가 너무 빨리 끝났으면 좀 더 해보자
          }
        }
        // 큐가 여유롭지 않은 상태
        // 일정 시간 쉰 후에 다시 시도
        shared.context = {
          ...shared.context,
          state: BgTaskState.Throttling,
        };
        setTimeout(checker, config.throttle_sleep);
        break;
      }
      case BgTaskState.Idle:
      case BgTaskState.Throttling: {
        // 어느 경우에건 다시 한번 큐 길이를 검사하고 task 를 실행 시도한다
        shared.context = {
          ...shared.context,
          state: BgTaskState.Checking,
          start_time: Date.now(),
        };
        config.immediate_fn(checker);
        break;
      }
    }
  };
  // 새로운 bgtask 를 스케줄링 큐에 추가한다.
  // 만약 비어있었다면 timeout 에 checker 를 등록한다.
  const add_task = (task: () => unknown) => {
    shared.context.queue.push(task);
    if (shared.context.state === BgTaskState.Idle) {
      shared.context = {
        ...context,
        state: BgTaskState.Checking,
        start_time: Date.now(),
      };
      config.immediate_fn(checker);
    }
  };

  return add_task;
};
