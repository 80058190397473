import React from "react";
import GoonoModalManager from "./GoonoModalManager";
import GoonoSnackbarManager from "./GoonoSnackbarManager";

export default function GlobalComponentManager() {
  return (
    <React.Fragment>
      <GoonoModalManager />
      <GoonoSnackbarManager />
    </React.Fragment>
  );
}
